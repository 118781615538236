/**
 *  Plan store module file
 **/

import { state } from './state'
import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export {
  MODULE_NAME as MODULE_NAME_PLAN,
  ACTIONS as ACTIONS_PLAN,
  GETTERS as GETTERS_PLAN,
  MUTATIONS as MUTATIONS_PLAN,
} from './constants'
