import { Environment } from '@workspaces/types'

export default class BrowserEnvironmentResolver
implements Environment.EnvironmentResolver {
  private readonly environment: string | undefined
  private readonly testing: string | undefined

  private static instance: Environment.EnvironmentResolver

  private constructor() {
    this.environment = process.env.VUE_APP_ENV
    this.testing = process.env.VUE_APP_TESTING
  }

  getLocation(): string {
    throw new Error('BrowserEnvironmentResolver has no location')
  }

  public static getInstance(): Environment.EnvironmentResolver {
    if (!BrowserEnvironmentResolver.instance) {
      BrowserEnvironmentResolver.instance = new BrowserEnvironmentResolver()
    }

    return BrowserEnvironmentResolver.instance
  }

  getEnvironment(): string {
    switch (this.environment) {
      case 'development':
        return Environment.Environment.Development
      case 'staging':
        return Environment.Environment.Staging
      case 'production':
        return Environment.Environment.Production
      default:
        throw new Error(
          `Unknown environment for value [${this.environment}]. Check .env file and VUE_APP_ENV variable`,
        )
    }
  }

  getEnvironmentForBQ(): string {
    const environment = this.getEnvironment()
    return this.isTesting() ? `${environment}_test` : environment
  }

  getEnvironmentVariable(name: string): string | undefined {
    const environmentVaraibleValue = process.env[name]
    if (environmentVaraibleValue) {
      return environmentVaraibleValue
    }
    throw new Error(`Environment variable ${name} not found`)
  }

  isTesting(): boolean {
    if (this.testing === undefined) {
      console.warn(
        'VUE_APP_TESTING environment variable not found. Assuming false',
      )
    }
    return this.testing === 'true'
  }
}
