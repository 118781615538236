import layerService from '@/layers/layerService'
import { BOUNDARIES_LAYER, EDITABLE_DATA_LAYER } from '@/layers'

export function manageBoundariesLayerVisibility(legend: any): void {
  // legend props
  const legendBoundariesVisibility = Object.hasOwn(
    legend,
    'boundariesVisibility',
  )
    ? legend.boundariesVisibility
    : true

  if (legendBoundariesVisibility) {
    layerService.showLayer(BOUNDARIES_LAYER)
    layerService.showLayer(EDITABLE_DATA_LAYER)
  } else {
    layerService.hideLayer(BOUNDARIES_LAYER)
    layerService.hideLayer(EDITABLE_DATA_LAYER)
  }
}
