import { getInstance as getAuth0ServiceIntance } from '@/auth/index'
import { SimpleAuditEvent } from './audit.type'
import { Audit, Environment, Metadata } from '@workspaces/types'
import { AuditService } from '@workspaces/services'
import { serializeFilters } from '@/utils/serializeFilters'
import { isAuditEnabled } from '@/plan/metadata/metadata.helper'

const eventsWithoutParams = [
  Audit.AuditEventType.Login,
  Audit.AuditEventType.LoginFirstTime,
]
function getParams(simpleAuditEvent: SimpleAuditEvent): string {
  if (eventsWithoutParams.includes(simpleAuditEvent.type)) {
    return ''
  }
  const serializedFilters = serializeFilters(simpleAuditEvent.params.filters)
  const filtersToString = JSON.stringify(serializedFilters)
  const params = { ...simpleAuditEvent.params }
  params.filters = filtersToString
  const paramsFormatted = JSON.stringify(params)
  return paramsFormatted
}

const eventUsageValues = [
  Audit.AuditEventType.PlanCreated,
  Audit.AuditEventType.PlanSaved,
  Audit.AuditEventType.PlanOpened,
  Audit.AuditEventType.PlanDeleted,
  Audit.AuditEventType.PlanShared,
  Audit.AuditEventType.PlanInsights,
  Audit.AuditEventType.PlanExported,
  Audit.AuditEventType.PlanLoadedUsage,
]
function getUsageValues(simpleAuditEvent: SimpleAuditEvent): string | null {
  if (eventUsageValues.includes(simpleAuditEvent.type)) {
    return JSON.stringify({
      id: simpleAuditEvent.params.id,
      name: simpleAuditEvent.params.name,
    })
  }

  return null
}

export default {
  async createEvent(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    simpleAuditEvent: SimpleAuditEvent,
  ): Promise<string> {
    if (!isAuditEnabled(meta)) {
      return ''
    }
    try {
      const params =
        getUsageValues(simpleAuditEvent) || getParams(simpleAuditEvent)
      const auth0Instance = getAuth0ServiceIntance()
      const userId = auth0Instance.getUser()
      const auditEvent: Audit.AuditEvent = {
        ...simpleAuditEvent,
        params,
        user_email: userId,
        created_at: new Date(),
      }
      // eslint-disable-next-line no-console
      console.debug(
        `Creating audit event of type ${simpleAuditEvent.type} for user ${userId}`,
      )
      const id = await AuditService.createEvent(meta, environment, auditEvent)
      return id
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      const auditEventType = simpleAuditEvent.type ?? 'Unknown event type'
      throw new Error(`Audit event ${auditEventType} could not be created`)
    }
  },
}
