import { Environment, Metadata, Package } from '@workspaces/types'
import { PackagesService } from '@workspaces/services'

export default {
  async getAllPackages(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
  ): Promise<Package.Package[]> {
    const data: Package.Package[] =
      await PackagesService.getAllPackagesWithAllProperties(meta, environment)
    return data
  },
  async getPackageAvailability(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    startDate: string,
    endDate: string,
    packageId: number,
  ): Promise<number> {
    const data: number = await PackagesService.getPackageAvailability(
      meta,
      environment,
      startDate,
      endDate,
      packageId,
    )
    return data
  },
  async getPackageClassificationValues(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
  ): Promise<Package.PackageClassifiactionValues> {
    const data: Package.PackageClassifiactionValues =
      await PackagesService.getPackageClassificationValues(meta, environment)
    return data
  },
}
