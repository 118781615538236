export enum Environment {
  Development = 'dev',
  Production = 'pro',
  Staging = 'stg',
}

export interface EnvironmentResolver {
  getEnvironment(): string

  getEnvironmentVariable(name: string): string | undefined

  isTesting(): boolean

  getLocation(): string

  getEnvironmentForBQ(): string
}
