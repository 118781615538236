export enum CustomGeoboundariesActions {
  StoreFileData = 'store_file_data',
  CheckStatusStoreFileData = 'check_status_store_file_data',
  ProcessFileData = 'process_file_data',
  GetSignedUrl = 'signed_url',
  DeleteFileData = 'delete_file_data',
}

export interface JobInfo {
  jobId: string
  status: string
  error?: string
}
