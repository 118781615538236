export interface CartoMe {
  groups?: string[]
  email: string
  name: string
}

export interface CartoAuthorizer {
  getToken(): string
}

export interface MetricEvent {
  event_type: string
  timestamp: string
  payload: {
    id: string
    user_id: string
    template: string
    metadata_org: string
  }
}

export const METRIC_EVENT_PATTERN_TO_REPLACE = 'XX'

export enum MetricEventType {
  Login = 'RadarviewXX_Login',
  SolutionBusinessValue = 'RadarviewXX_SolutionBusinessValue',
}
