import { Filter } from '../filter.type'
import { AssetDataGeoJsonLayer } from '../asset.type'
import { FilterAppliedResultAudiences } from '../plan.type'
import {
  AudienceType,
  AudienceV2Filter,
  AudienceV2Params,
  AudiencesForfilteringAssetsQueryParams,
} from './audienceV2.type'
import { getAppMetadata } from '../../metadata/metadata'
import { AudienceClass } from './audience.type'
import { Environment } from '@workspaces/types'
import AssetsService from '@/services/assets.service'

function audiencesClassIdFormatted(audiencesRaw: AudienceClass[]): string[] {
  if (!audiencesRaw) {
    return []
  }

  const audiences = audiencesRaw.map((audience) => audience.ids[0])
  return audiences
}

export async function filterAssetsByAudienceV2(
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
): Promise<FilterAppliedResultAudiences> {
  const filterAudiences: AudienceV2Filter = {
    andGroup: filter.audiences,
    orGroup: filter.audiencesGroups,
  }

  if (
    filterAudiences === undefined ||
    (filterAudiences.andGroup.length === 0 &&
      filterAudiences.orGroup.length === 0)
  ) {
    return { assets, filterApplied: false, assetsAudience: [] }
  }

  console.debug('      👉  Preparing params for audience query')
  const audiences: AudienceV2Params = {
    andGroup: audiencesClassIdFormatted(filterAudiences.andGroup),
    orGroup: audiencesClassIdFormatted(filterAudiences.orGroup),
  }

  const metadata = getAppMetadata()
  const queryParams: AudiencesForfilteringAssetsQueryParams = {
    andGroup: audiences.andGroup.length > 0 ? audiences.andGroup : undefined,
    orGroup: audiences.orGroup.length > 0 ? audiences.orGroup : undefined,
    delivery: filter.delivery,
    country_ids: filter.countries.length > 0 ? filter.countries : undefined,
    isIndexActive: filter.audiencesType === AudienceType.Index,
    isPercentageActive: filter.audiencesType === AudienceType.Percentage,
    lowerRange: -1,
    upperRange: -1,
  }

  if (
    (!queryParams.isIndexActive && !queryParams.isPercentageActive) ||
    (queryParams.isIndexActive && queryParams.isPercentageActive)
  ) {
    const msg = `🛑 Audience type ${filter.audiencesType} is not valid. Expected Index or Percentage`
    // eslint-disable-next-line no-console
    console.error(msg)
    throw new Error(msg)
  }

  if (queryParams.isIndexActive) {
    if (Array.isArray(filter.audiencesIndex)) {
      queryParams.lowerRange = filter.audiencesIndex[0]
      queryParams.upperRange = filter.audiencesIndex[1]
    } else {
      const msg = `🛑 Audience index ${filter.audiencesIndex} is not an array. Expected array for defining filter values for audiences`
      // eslint-disable-next-line no-console
      console.error(msg)
      throw new Error(msg)
    }
  } else if (queryParams.isPercentageActive) {
    if (Array.isArray(filter.audiencesPercent)) {
      queryParams.lowerRange = filter.audiencesPercent[0]
      queryParams.upperRange = filter.audiencesPercent[1]
    } else {
      const msg = `🛑 Audience percentage ${filter.audiencesPercent} is not an array. Expected array for defining filter values for audiences`
      // eslint-disable-next-line no-console
      console.error(msg)
      throw new Error(msg)
    }
  }
  console.debug('             OK - Preparing params for audience query')

  console.debug('      👉  Fetching assets')
  const filteredAssetsIdIndex =
    await AssetsService.getAssetsToShowBasedOnAudienceFilterV2(
      metadata,
      environment,
      queryParams,
    )
  console.debug('             OK - Fetching assets')

  console.debug('🟢 Filtering assets ...')
  const filteredAssetsId: Map<string, number> = new Map()
  filteredAssetsIdIndex.forEach((asset) =>
    filteredAssetsId.set(asset.asset_id, asset.index),
  )
  let filteredAssets = assets.filter((asset) => {
    const hit = filteredAssetsId.get(asset.properties.id)
    if (hit !== undefined) {
      asset.properties.audienceIndex = hit
      return true
    }
    return false
  })
  console.debug('🟢 OK - Filtering assets', filteredAssets.length)

  if (queryParams.isPercentageActive) {
    console.debug('      👉  Filtering percentage range')
    if (
      queryParams.upperRange &&
      queryParams.lowerRange !== undefined &&
      queryParams.lowerRange !== null &&
      queryParams.lowerRange >= 0
    ) {
      const totalAssets = filteredAssets.length
      const upperIndex =
        totalAssets - Math.round(queryParams.upperRange * 0.01 * totalAssets)
      const lowerIndex =
        totalAssets - Math.round(queryParams.lowerRange * 0.01 * totalAssets)
      // Sort filtered assets by properties.audienceIndex in descending order
      filteredAssets.sort(
        (a, b) => b.properties.audienceIndex - a.properties.audienceIndex,
      )
      filteredAssets = filteredAssets.slice(upperIndex, lowerIndex)
    } else {
      const msg = `🛑 Audience percentage ${filter.audiencesPercent} was not able to be decomposed as lower and upper value`
      // eslint-disable-next-line no-console
      console.error(msg)
      throw new Error(msg)
    }
    console.debug('             OK - Filtering percentage range')
  }

  return {
    assets: filteredAssets,
    filterApplied: true,
    assetsAudience: filteredAssetsIdIndex,
  }
}

export function convertAudienceMondayDatetoWeek(mondayDate: string): string {
  const date = new Date(mondayDate)
  const week = Math.ceil(
    (date.getTime() - new Date(date.getFullYear(), 0, 1).getTime()) /
      (7 * 24 * 60 * 60 * 1000),
  )
  const year = date.getFullYear()
  return ` ${week}, ${year}`
}
