/** * Tooltip.vue **/ /* template import */
<template src="./tooltip.html"></template>
/* style import */
<style scoped lang="scss" src="./tooltip.scss"></style>
<script>
export default {
  name: 'CartoComponentTooltip',
  components: {
    TooltipComponent: () => import('./tooltip-component/TooltipComponent.vue'),
  },
  props: {
    text: {
      type: [String, Number, Boolean],
      required: false,
    },
    position: {
      type: String,
      default: 'top',
      required: false,
    },
    margin: {
      type: Number,
      required: false,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    multilineSmall: {
      type: Boolean,
      default: false,
    },
    multilineManual: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      bbox: null,
      textValue: this.text,
    }
  },
  mounted() {
    if (this.textValue) {
      this.$el.addEventListener('mouseover', this.show, false)
      this.$el.addEventListener('mouseout', this.hide, false)
    }
  },
  beforeDestroy() {
    this.$el.removeEventListener('mouseover', this.show)
    this.$el.removeEventListener('mouseout', this.hide)
    window.removeEventListener('scroll', this.hide)
  },
  methods: {
    show() {
      this.bbox = this.$el.getBoundingClientRect()
      this.visible = true
      window.addEventListener('scroll', this.hide, false)
    },
    hide() {
      this.bbox = null
      this.visible = false
      window.removeEventListener('scroll', this.hide)
    },
  },
  watch: {
    text(value) {
      this.textValueValue = value
    },
  },
}
</script>
