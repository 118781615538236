import { Metadata, Environment, Firebase } from '@workspaces/types'
import { PlanService } from '@workspaces/services'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { isPackagagesFeatureEnabled } from '@/plan/metadata/metadata.helper'
import { commit } from '@/utils/vuex'
import { MODULE_NAME_PLAN } from '@/store/plan'
import { MUTATIONS } from '@/store/plan/constants'

export async function getUserAgencyId(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  carto3Token: string,
  showWarningMessage = true,
): Promise<string | null> {
  const firebaseToken = await PlanService.getFirebaseToken(
    meta,
    environment,
    carto3Token,
  )
  const firebaseTokenPayload: Firebase.FirebaseTokenPayload = jwt_decode(
    firebaseToken,
  ) as Firebase.FirebaseTokenPayload
  if (firebaseTokenPayload === null) {
    throw new Error(
      '🔴🔴🔴 No payload found in firebase token. Check metadata file 🔴🔴🔴',
    )
  }
  const agency =
    firebaseTokenPayload.claims.roles && firebaseTokenPayload.claims.roles[0]
  if (!agency) {
    console.warn('👩🏻 User has no agency set. User needs to have an agency set.')
    if (showWarningMessage) {
      commit(MODULE_NAME_PLAN, MUTATIONS.SET_FORCE_LOGOUT, true)
    }
    return null
  }

  return agency
}

export async function checkIfUserHasAgency(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  carto3Token: string,
): Promise<boolean> {
  if (!isPackagagesFeatureEnabled(meta)) {
    return false
  }
  console.debug(
    '👩🏻 We are managing packages, so checking if user has agency set',
  )
  const agency = await getUserAgencyId(meta, environment, carto3Token, true)
  return !!agency
}

export async function getAgencyNameForIds(
  Ids: string[],
): Promise<{ id: string; name: string }> {
  console.debug('getAgencyNameForIds', Ids)
  throw new Error('Not implemented function getAgencyNameForIds')
}
