export enum AudienceType {
  Index = 'index',
  Percentage = 'percent',
}

export interface YearMonthOrWeek {
  year: number
  month?: number
  week?: number
}

export interface Delivery {
  dateFormat: string
  humanFormat: string
}

export interface FilterByAudienceParamsRequest {
  audiences: string[][]
}

export interface AudienceByAttrs {
  uid: string
  attr1: string
  attr2: string
  attr3: string
  attr4: string
  attr5: string
  attr6: string
  name: string
}

export enum AudienceFeatureVersion {
  V1 = 1,
  V2 = 2,
}

export interface Audience extends AudienceByAttrs {
  year_month_week: Date
  country_iso3?: string
  class_group: string
  audience: string
  type: number
}

export interface AudienceAssetIndex {
  name: string
  value: number
}

export interface GetDeliveriesRequestParams {
  country_ids?: number[]
}

export interface GetAudiencesV2RequestParams {
  country_ids?: number[]
  delivery: string
}

export interface AudiencesForfilteringAssetsQueryParams {
  andGroup?: string[]
  orGroup?: string[]
  delivery: string
  country_ids?: number[]
  isIndexActive: boolean
  isPercentageActive: boolean
  lowerRange?: number
  upperRange?: number
}
