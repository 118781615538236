export function parseParams(params) {
  return params
    ? Object.keys(params)
      .filter((key) => {
        const val = params[key]
        return Array.isArray(val) ? val.length > 0 : val !== undefined // explicit undefined, not null, false, '' or 0
      })
      .map((key) => {
        const value = Array.isArray(params[key])
          ? params[key].join(',')
          : params[key]
        return `${key}=${value}`
      })
      .join('&')
    : ''
}
