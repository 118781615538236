import { Filter, SimplePlanWithFilters } from './filter.type'
import { AssetDataGeoJsonLayer, AssetIdIndex } from './asset.type'
import {
  filterAssetsByAudience,
  propagateAudienceDelivery,
} from './audience/audience.helper'
import {
  filterAssetsByExcludedGeoboundary,
  filterAssetsByGeoboundary,
  filterAssetsByGeoboundaryRegion18,
  unionOfAssets,
} from './geoboundary.helper'
import {
  setExcludedPropertyToAssetsBySubFilter,
  filterAssetsByInventory,
  filterAssetsByOptimizeDistribution,
  addIncludedAssetsFromExcludeLayer,
  filterAssetsByInventoryOverwrittingAssets,
} from './asset.helper'
import {
  filterAssetsByMediaType,
  filterAssetsByDigital,
} from './mediatype.helper'
import { filterAssetsByPackages } from './packages.helper'
import { filterAssetsByCountry } from './country.helper'
import { FilterAppliedResult, FilterAppliedResultAudiences } from './plan.type'
import {
  filterAssetsByProximity,
  getFilterByPoisParamsRequestFromPoisfilter,
} from './pois.helper'
import { getAppMetadata } from '../metadata/metadata'
import { Environment } from '@workspaces/types'
import { GeographicalDistributionRegion } from './geographicalDistribution.type'

import {
  getOptimizeDistributionRegions,
  hasOptimizeDistributionFilter,
} from './geographicalDistribution.helper'
import {
  filterAssetsByCustomGeoboundaryDatasets,
  filterAssetsByExcludedCustomGeoboundaryDatasets,
} from './custom-geoboundary-dataset.helper'
import { isPackagagesFeatureEnabled } from '../metadata/metadata.helper'

// import { GeographicalDistributionRegion } from './geographicalDistribution.type'
// import { getters } from '@/utils'
// import { MODULE_NAME_PLAN, GETTERS_PLAN } from '@/store/plan'
// import { sleep } from './common.helper'

function getAssetsUnionCombination(
  assets1: AssetDataGeoJsonLayer[],
  assets2: AssetDataGeoJsonLayer[],
): AssetDataGeoJsonLayer[] {
  if (assets1.length === 0) {
    return assets2
  }
  if (assets2.length === 0) {
    return assets1
  }
  const allAssets = [...assets1, ...assets2]
  const assetsAfterUnion = [
    ...new Map(allAssets.map((item) => [item.properties.id, item])).values(),
  ]

  return assetsAfterUnion
}

/**
 * Get the resultant assets from applying a sub filter to all the assets
 * @param assets Assets to be analyzed
 * @param subFilter Porperties of the sub filter to be applied
 * @returns Assets that match the sub filter and intersect with the assets passed as parameter
 */
async function filterAssetsFromSubFilter(
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  subFilter: Filter,
  latestDelivery: string,
): Promise<FilterAppliedResultAudiences> {
  console.debug('  ⏳ - Filtering assets from sub filter starting ...')
  const metadata = getAppMetadata()
  let assetsFiltered: AssetDataGeoJsonLayer[] = [...assets]
  let appliedFilterAtAnyStage = false
  let assetsAudience: AssetIdIndex[] = []

  // Country filter
  if (metadata.plan_filters.widget_countries.enable) {
    const t0 = new Date().getTime()
    const countriesPartialFilterResult: FilterAppliedResult =
      filterAssetsByCountry(assetsFiltered, subFilter.countries)
    console.debug(
      `     🔍 Countries || IN: ${assetsFiltered.length}  OUT: ${
        countriesPartialFilterResult.assets.length
      }  TIME: ${new Date().getTime() - t0}`,
    )
    assetsFiltered = countriesPartialFilterResult.assets
    appliedFilterAtAnyStage =
      countriesPartialFilterResult.filterApplied || appliedFilterAtAnyStage
  }

  // Geoboundary filter
  if (metadata.plan_filters.widget_geoboundaries.enable) {
    const t0 = new Date().getTime()
    let appliedGeoboundaryFilterAtAnyStage = false
    const assetsCountAtStart = assetsFiltered.length
    // Included filters
    const geoboundaryPartialFilterResult: FilterAppliedResult =
      filterAssetsByGeoboundary(
        assetsFiltered,
        subFilter.geoboundaries,
        subFilter.polygon_geom,
      )
    console.debug(
      `            Included regions, resultant assets: ${geoboundaryPartialFilterResult.assets.length}`,
    )
    // Region 18 filter: filter by custom geoboundary external file
    const geoboundaryPartialFilterResultRegion18: FilterAppliedResult =
      await filterAssetsByGeoboundaryRegion18(
        environment,
        metadata,
        assetsFiltered,
        subFilter.geoboundaries,
      )

    appliedGeoboundaryFilterAtAnyStage =
      geoboundaryPartialFilterResult.filterApplied ||
      geoboundaryPartialFilterResultRegion18.filterApplied ||
      appliedGeoboundaryFilterAtAnyStage

    let partialUnionOfAssetsFiltered: AssetDataGeoJsonLayer[] = []

    if (
      !appliedGeoboundaryFilterAtAnyStage &&
      !geoboundaryPartialFilterResultRegion18.filterApplied
    ) {
      console.debug('            No region 18 geoboundary filter applied')
    } else {
      partialUnionOfAssetsFiltered = unionOfAssets(
        geoboundaryPartialFilterResult.assets,
        geoboundaryPartialFilterResultRegion18.assets,
      )
    }

    // Custom geoboundaries datasets
    const customGeoboundaryDatasetsPartialFilterResult: FilterAppliedResult =
      await filterAssetsByCustomGeoboundaryDatasets(
        environment,
        assetsFiltered,
        subFilter,
      )
    console.debug(
      `            Included custom geoboundaries dataset, resultant assets: ${customGeoboundaryDatasetsPartialFilterResult.assets.length}`,
    )

    if (
      !appliedGeoboundaryFilterAtAnyStage &&
      !customGeoboundaryDatasetsPartialFilterResult.filterApplied
    ) {
      console.debug('            No included geoboundary filter applied')
    } else {
      assetsFiltered = unionOfAssets(
        partialUnionOfAssetsFiltered,
        customGeoboundaryDatasetsPartialFilterResult.assets,
      )
    }

    console.debug(`            Included assets: ${assetsFiltered.length}`)
    appliedGeoboundaryFilterAtAnyStage =
      customGeoboundaryDatasetsPartialFilterResult.filterApplied ||
      appliedGeoboundaryFilterAtAnyStage

    // Excluded custom geoboundaries datasets
    const geoboundaryPartialFilterResult4: FilterAppliedResult =
      await filterAssetsByExcludedCustomGeoboundaryDatasets(
        environment,
        assetsFiltered,
        subFilter,
      )
    console.debug(
      `            Excluded custom geoboundaries dataset, resultant assets: ${geoboundaryPartialFilterResult4.assets.length}`,
    )
    assetsFiltered = geoboundaryPartialFilterResult4.assets
    appliedGeoboundaryFilterAtAnyStage =
      geoboundaryPartialFilterResult4.filterApplied ||
      appliedGeoboundaryFilterAtAnyStage

    // Excluded filters
    const geoboundaryPartialFilterResult3: FilterAppliedResult =
      filterAssetsByExcludedGeoboundary(assetsFiltered, subFilter.geoboundaries)
    console.debug(
      `            Excluded regions, resultant assets: ${geoboundaryPartialFilterResult3.assets.length}`,
    )
    assetsFiltered = geoboundaryPartialFilterResult3.assets
    appliedGeoboundaryFilterAtAnyStage =
      geoboundaryPartialFilterResult3.filterApplied ||
      appliedGeoboundaryFilterAtAnyStage

    // const excludedAssets = unionOfAssets(
    //   geoboundaryPartialFilterResult4.assets,
    //   geoboundaryPartialFilterResult3.assets
    // )
    // console.debug(`      Excluded assets: ${excludedAssets.length}`)

    // assetsFiltered = removeExcludedAssets(includedAssets, excludedAssets)
    // console.debug(`      Assets after removing excluded assets: ${assetsFiltered.length}`)
    appliedFilterAtAnyStage =
      appliedFilterAtAnyStage || appliedGeoboundaryFilterAtAnyStage
    console.debug(
      `     🔍 Geoboundaries || IN: ${assetsCountAtStart}  OUT: ${
        assetsFiltered.length
      }  TIME: ${new Date().getTime() - t0}`,
    )
  }

  // Audience filter
  if (metadata.plan_filters.widget_audiences.enable) {
    const t0 = new Date().getTime()
    const audiencePartialResult: FilterAppliedResultAudiences =
      await filterAssetsByAudience(
        environment,
        assetsFiltered,
        subFilter,
        latestDelivery,
      )
    console.debug(
      `     🔍 Audiences || IN: ${assetsFiltered.length}  OUT: ${
        audiencePartialResult.assets.length
      }  TIME: ${new Date().getTime() - t0}`,
    )
    assetsFiltered = audiencePartialResult.assets
    appliedFilterAtAnyStage =
      audiencePartialResult.filterApplied || appliedFilterAtAnyStage
    assetsAudience = audiencePartialResult.assetsAudience
  }

  // Digital filter
  if (
    metadata.plan_filters.widget_media_type.enable &&
    metadata.plan_filters.widget_media_type.filter_digital
  ) {
    const t0 = new Date().getTime()
    const digitalPartialResult: FilterAppliedResult = filterAssetsByDigital(
      assetsFiltered,
      subFilter,
    )
    console.debug(
      `     🔍 Digital || IN: ${assetsFiltered.length}  OUT: ${
        digitalPartialResult.assets.length
      }  TIME: ${new Date().getTime() - t0}`,
    )

    assetsFiltered = digitalPartialResult.assets
    appliedFilterAtAnyStage =
      digitalPartialResult.filterApplied || appliedFilterAtAnyStage
  }

  // Media types filter
  if (metadata.plan_filters.widget_media_type.enable) {
    const t0 = new Date().getTime()
    const mediaTypePartialResult: FilterAppliedResult = filterAssetsByMediaType(
      assetsFiltered,
      subFilter,
    )
    console.debug(
      `     🔍 Media Type || IN: ${assetsFiltered.length}  OUT: ${
        mediaTypePartialResult.assets.length
      }  TIME: ${new Date().getTime() - t0}`,
    )

    assetsFiltered = mediaTypePartialResult.assets
    appliedFilterAtAnyStage =
      mediaTypePartialResult.filterApplied || appliedFilterAtAnyStage
  }

  // Packages filter
  if (metadata.plan_filters.widget_packages.enable) {
    const t0 = new Date().getTime()
    const packagesPartialResult: FilterAppliedResult = filterAssetsByPackages(
      assetsFiltered,
      subFilter,
    )
    console.debug(
      `     🔍 Packages || IN: ${assetsFiltered.length}  OUT: ${
        packagesPartialResult.assets.length
      }  TIME: ${new Date().getTime() - t0}`,
    )

    assetsFiltered = packagesPartialResult.assets
    appliedFilterAtAnyStage =
      packagesPartialResult.filterApplied || appliedFilterAtAnyStage
  }

  // Proximity filter
  if (metadata.plan_filters.widget_proximity.enable) {
    const t0 = new Date().getTime()
    const proximityPartialResult: FilterAppliedResult =
      await filterAssetsByProximity(environment, assetsFiltered, subFilter)
    const incomingAssetsForPois = assetsFiltered
    assetsFiltered = proximityPartialResult.assets
    appliedFilterAtAnyStage =
      proximityPartialResult.filterApplied || appliedFilterAtAnyStage

    if (proximityPartialResult.filterApplied) {
      const poisFilter = getFilterByPoisParamsRequestFromPoisfilter(
        subFilter.proximity,
      )
      if (poisFilter.max_assets_per_poi > 0) {
        const proximityPartialResult: FilterAppliedResult =
          await filterAssetsByProximity(
            environment,
            assetsFiltered,
            subFilter,
            false,
          )
        assetsFiltered = proximityPartialResult.assets
        appliedFilterAtAnyStage =
          proximityPartialResult.filterApplied || appliedFilterAtAnyStage
      }
    }

    console.debug(
      `     🔍 Proximity || IN: ${incomingAssetsForPois.length}  OUT: ${
        proximityPartialResult.assets.length
      }  TIME: ${new Date().getTime() - t0}`,
    )
  }

  // Inventory assets ids filter
  if (metadata.plan_filters.widget_assets.enable) {
    const t0 = new Date().getTime()
    let inventoryPartialResult: FilterAppliedResult
    if (!subFilter?.panels.ids) {
      inventoryPartialResult = { assets: assetsFiltered, filterApplied: false }
    } else {
      if (isPackagagesFeatureEnabled(metadata)) {
        // If package feature is enable, means FI flavour, we need to set only the assets that are provided in the uploaded file
        inventoryPartialResult = filterAssetsByInventoryOverwrittingAssets(
          assets,
          subFilter,
        )
      } else {
        inventoryPartialResult = filterAssetsByInventory(
          assets,
          assetsFiltered,
          subFilter,
        )
      }
    }
    console.debug(
      `     🔍 Inventory || IN: ${assetsFiltered.length}  OUT: ${
        inventoryPartialResult.assets.length
      }  TIME: ${new Date().getTime() - t0}`,
    )

    assetsFiltered = inventoryPartialResult.assets
    appliedFilterAtAnyStage =
      inventoryPartialResult.filterApplied || appliedFilterAtAnyStage
  }

  // Included assets via map
  let t0 = new Date().getTime()
  const includedAssetsPartialResult: FilterAppliedResult =
    addIncludedAssetsFromExcludeLayer(
      assets,
      assetsFiltered,
      subFilter.assets_included,
    )
  console.debug(
    `     🔍 Included || IN: ${assetsFiltered.length}  OUT: ${
      includedAssetsPartialResult.assets.length
    }  TIME: ${new Date().getTime() - t0}`,
  )
  assetsFiltered = includedAssetsPartialResult.assets
  appliedFilterAtAnyStage =
    includedAssetsPartialResult.filterApplied || appliedFilterAtAnyStage

  // Exclude assets
  t0 = new Date().getTime()
  const assetsFilteredExcluded = setExcludedPropertyToAssetsBySubFilter(
    assets,
    assetsFiltered,
    [...subFilter.assets_excluded],
  )
  console.debug(
    `     🔍 Excluded || IN: ${assetsFiltered.length}  OUT: ${
      assetsFilteredExcluded.length
    }  TIME: ${new Date().getTime() - t0}`,
  )
  assetsFiltered = assetsFilteredExcluded
  appliedFilterAtAnyStage =
    appliedFilterAtAnyStage || subFilter.assets_excluded.length > 0

  console.debug('  ⏳ - Filtering assets from sub filter Ended')
  return {
    assets: assetsFiltered,
    filterApplied: appliedFilterAtAnyStage,
    assetsAudience,
  }
}

/**
 *
 * @param assets Assets to be analyzed
 * @param planWithFilters Sub filters that compose the filter defined by the user
 * @returns Assets that match the filter defined by the user and intersect with the assets passed as parameter
 */
export async function filterAssetsFromPlan(
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  planWithFilters: SimplePlanWithFilters,
  latestDelivery: string,
): Promise<AssetDataGeoJsonLayer[]> {
  const metadata = getAppMetadata()
  let assetsFiltered: AssetDataGeoJsonLayer[] = []
  let filterAppliedAtAnySubFilter = false
  const planFilterUpdated = propagateAudienceDelivery(planWithFilters.filters)

  const audiencesAssetIdAndIndex: AssetIdIndex[][] = []

  for (const subFilter of planFilterUpdated) {
    const {
      assets: assetsFilteredFromSubPlan,
      filterApplied,
      assetsAudience,
    }: FilterAppliedResultAudiences = await filterAssetsFromSubFilter(
      environment,
      assets,
      subFilter,
      latestDelivery,
    )
    filterAppliedAtAnySubFilter = filterAppliedAtAnySubFilter || filterApplied
    if (filterApplied) {
      assetsFiltered = getAssetsUnionCombination(
        assetsFiltered,
        assetsFilteredFromSubPlan,
      )
    }
    if (assetsAudience) {
      audiencesAssetIdAndIndex.push(assetsAudience)
    }
  }

  const iteratedFilteredAssets = filterAppliedAtAnySubFilter
    ? assetsFiltered
    : assets

  // OPTIMIZE DISTRIBUTION
  // Filter optimize distribution to the complete set of filtered assets
  if (
    metadata.plan_filters.widget_optimize_distribution.enable &&
    hasOptimizeDistributionFilter(planFilterUpdated)
  ) {
    console.debug(
      '⏳ - Filtering assets from geographic optimization starting ...',
    )
    const geographicalDistributionRegions: GeographicalDistributionRegion[] =
      await getOptimizeDistributionRegions(
        metadata,
        environment,
        planFilterUpdated,
      )

    const { assets: optimizeDistributionResults }: FilterAppliedResult =
      await filterAssetsByOptimizeDistribution(
        metadata,
        environment,
        iteratedFilteredAssets,
        planFilterUpdated[0],
        geographicalDistributionRegions,
        audiencesAssetIdAndIndex,
      )
    console.debug('  ⏳ - Filtering assets from geographic optimization Ended')

    return optimizeDistributionResults
  }

  return iteratedFilteredAssets
}

/**
 *
 * @param assets Assets to be analyzed
 * @param excluded List of assets ids to be excluded
 * @returns Assets with the excluded property set to true if the asset id is in the excluded list
 */
export async function excludeAssetsFromPlan(
  assets: AssetDataGeoJsonLayer[],
  excluded: string[],
): Promise<AssetDataGeoJsonLayer[]> {
  return assets.map((asset) => {
    asset.properties.excluded = false
    if (excluded.includes(asset.properties.id)) {
      asset.properties.excluded = true
    }
    return asset
  })
}
