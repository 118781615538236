import { render, staticRenderFns } from "./icon-buffer.html?vue&type=template&id=827ca5b4&scoped=true&"
import script from "./IconBuffer.vue?vue&type=script&lang=js&"
export * from "./IconBuffer.vue?vue&type=script&lang=js&"
import style0 from "./icon-buffer.scss?vue&type=style&index=0&id=827ca5b4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "827ca5b4",
  null
  
)

export default component.exports