import { AudienceClass } from './audience.type'

export interface AudienceV2Filter {
  andGroup: AudienceClass[]
  orGroup: AudienceClass[]
}

export interface AudienceV2Params {
  andGroup: string[]
  orGroup: string[]
}

export enum AudienceType {
  Index = 'index',
  Percentage = 'percent',
}

export interface AudiencesForfilteringAssetsQueryParams {
  andGroup?: string[]
  orGroup?: string[]
  delivery: string
  country_ids?: number[]
  isIndexActive: boolean
  isPercentageActive: boolean
  lowerRange?: number
  upperRange?: number
}
