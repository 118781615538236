/* eslint-disable @typescript-eslint/no-explicit-any */
import layerService from '@/layers/layerService'
import { CacheManager, AuthManager } from '@workspaces/services'
import { getZoomLevelForDeclustering } from '@/plan/metadata/metadata.helper'
import { ASSETS, CLUSTER_ASSETS } from '@/layers'
import { shouldAssetSimpleLayerBeVisible } from './map.helper'
import { Metadata, Asset } from '@workspaces/types'
import { getAppMetadata } from '@/plan/metadata/metadata'
import { commit } from '@/utils/vuex'
import { MODULE_NAME_PLAN } from '@/store/plan'
import { MUTATIONS } from '@/store/plan/constants'

const workersIdRunning: Set<number> = new Set()
let latestWorkerRunningId = 0

function updateAssetLayersData(
  meta: Metadata.AppMetadata,
  allAssets: Asset.GeoJSONAsset[],
): void {
  console.debug('🐨 Updating asset layer with all properties')
  const mapZoom = layerService.getZoom()
  const declusteringZoomLevel = getZoomLevelForDeclustering(meta)
  const assetSimpleLayerShouldBeVisible = shouldAssetSimpleLayerBeVisible(
    declusteringZoomLevel,
    mapZoom,
  )
  const assetsToShow: Asset.GeoJSONAsset[] =
    layerService.getFilteredDataFromAssetLayer()
  let assetsToShowWithAllProperties: Asset.GeoJSONAsset[] = []
  if (assetsToShow.length === allAssets.length) {
    console.debug(
      '    🐨 All assets are shown, no need to process assets filtered',
    )
    assetsToShowWithAllProperties = allAssets
  } else {
    console.debug('    🐨 Processing assets filtered, to set all properties')
    const mapOfAllAssets = new Map<string, Asset.GeoJSONAsset>()
    allAssets.forEach((asset) => mapOfAllAssets.set(asset.properties.id, asset))
    assetsToShowWithAllProperties = []
    assetsToShow.forEach((asset) => {
      const assetWithAllProperties = mapOfAllAssets.get(asset.properties.id)
      if (assetWithAllProperties) {
        assetsToShowWithAllProperties.push(assetWithAllProperties)
      } else {
        assetsToShowWithAllProperties.push(asset)
      }
    })
    console.debug('    🐨 All assets processed')
  }

  console.debug(
    `    🐨 Updating layers, visible layer would be simpleAssetLayer ${assetSimpleLayerShouldBeVisible} clusterAssetsLayer ${!assetSimpleLayerShouldBeVisible}`,
  )
  const assetsLayerVisibility = assetsToShow.length > 0
  console.debug('    🐨 Updating asset layer')
  layerService.updateLayer(ASSETS, {
    data: allAssets,
    assetsToShow: assetsToShowWithAllProperties,
    visible: assetsLayerVisibility && assetSimpleLayerShouldBeVisible,
    flyToExtent: false,
  })
  layerService.updateLayer(CLUSTER_ASSETS, {
    data: allAssets,
    assetsToShow: assetsToShowWithAllProperties,
    visible: assetsLayerVisibility && !assetSimpleLayerShouldBeVisible,
    flyToExtent: false,
  })
  console.debug('🐨 Updating asset layers finished')
}

export async function downloadAssetsPropertiesInBackground(
  assetsCountByCountriesToDownload: Asset.AssetsCountPerCountry[],
  chunkSize: number,
  assetsByCountryWithBasicProperties: Asset.AssetsGeoJsonFormattedByCountry[],
): Promise<void> {
  commit(MODULE_NAME_PLAN, MUTATIONS.SET_ASSETS_READY_WITH_ALL_PROPERTIES, {
    ready: false,
  })
  const workerId = Date.now()
  latestWorkerRunningId = workerId
  const meta = getAppMetadata()

  const lastUpdateTimestampAssetsTable =
    CacheManager.getInstance().getCacheInfoForTable(
      meta.data_model.assets.table_name,
    )

  if (!window.Worker) {
    const msg =
      '😱😱😱😱 Worker not supported, cannot download all assets properties'
    console.error(msg)
    throw new Error(msg)
  } else {
    if (workersIdRunning.has(workerId)) {
      console.error(
        `🐨 🚀 Worker already running with workerId ${workerId}, cannot start another worker`,
      )
      return
    }
    workersIdRunning.add(workerId)
    console.debug('🐨 🚀 Creating worker to fetch assets properties')
    const worker = new Worker(
      new URL('../workers/asset-propertiesV2.worker.ts', import.meta.url),
    )
    const token = AuthManager.getInstance().getToken()
    // Envía un mensaje al Worker
    worker.postMessage([
      token,
      JSON.parse(JSON.stringify(meta)),
      assetsCountByCountriesToDownload,
      chunkSize,
      lastUpdateTimestampAssetsTable,
      assetsByCountryWithBasicProperties,
      workerId,
    ])

    // Escucha los mensajes del Worker
    worker.onmessage = (event) => {
      console.debug(`🐨 🚀 Received message from Worker: ${event.data.type}`)
      if (event.data.type === 'done') {
        const data = event.data.data
        console.debug('    🐨 🚀 Worker finished, data sent:', { data })
        worker.terminate()
        const isLatestWorkerIdRunning = latestWorkerRunningId === workerId
        console.debug(
          `     Comparing latestWorkingIdRunning ${latestWorkerRunningId} with workerId ${workerId} isLatestWorkerIdRunning ${isLatestWorkerIdRunning}`,
        )
        if (isLatestWorkerIdRunning) {
          const { combinedAssets } = data
          updateAssetLayersData(meta, combinedAssets)
        }
        if (workersIdRunning.has(workerId)) {
          workersIdRunning.delete(workerId)
        } else {
          console.error(
            `🐨 🚀 Worker finished, but workerId ${workerId} not found in workersIdRunning`,
          )
        }
        const isNoWorkerRunning = workersIdRunning.size === 0
        const isLatestWorkerIdFinished = latestWorkerRunningId === workerId
        console.debug(
          '    🐨 🚀 All assets properties updated: ',
          isNoWorkerRunning,
        )
        commit(
          MODULE_NAME_PLAN,
          MUTATIONS.SET_ASSETS_READY_WITH_ALL_PROPERTIES,
          { ready: isNoWorkerRunning || isLatestWorkerIdFinished },
        )
      }
    }

    worker.onerror = (error) => {
      console.error(`Worker error: ${JSON.stringify(error)}`)
      if (workersIdRunning.has(workerId)) {
        workersIdRunning.delete(workerId)
      } else {
        console.error(
          `🐨 🚀 Worker finished, but workerId ${workerId} not found in workersIdRunning`,
        )
      }
      const isNoWorkerRunning = workersIdRunning.size === 0
      console.debug(
        '    🐨 🚀 All assets properties updated: ',
        isNoWorkerRunning,
      )
      commit(MODULE_NAME_PLAN, MUTATIONS.SET_ASSETS_READY_WITH_ALL_PROPERTIES, {
        ready: isNoWorkerRunning,
      })
    }
    console.debug('    🐨 🚀 Worker launched for fetching assets properties')
  }
}
