import { PROXIMITY_TYPES, GEO_BOUNDARIES_TYPES } from '@/constants'
import { ONE_MILE_TO_METER } from '@/utils/metersToPixels'
import { removeGeoboundaryCacheProperties } from '@/plan/geometries/geoboundary.helper'

export function serializeFilters(planFilters) {
  const filtersWithoutGeoboundaryCache =
    removeGeoboundaryCacheProperties(planFilters)

  const filtersSerialized = filtersWithoutGeoboundaryCache.map(
    (subPlanFilters) => {
      return {
        tabname: subPlanFilters.tabname || '',
        countries: serializeCountries(subPlanFilters.countries),
        geoboundaries: serializeGeoboundaries(subPlanFilters.geoboundaries),
        audiences: serializeAudiences(subPlanFilters),
        types: serializePanelTypes(subPlanFilters),
        excluded: subPlanFilters.assets_excluded,
        is_digital: serializeDigitalPanel(subPlanFilters.isDigital),
        included: subPlanFilters.assets_included,
        panels: subPlanFilters.panels,
        geographical_distribution: serializeGeographicalDistribution(
          subPlanFilters.geographical_distribution,
        ),
        panelsExcluded: subPlanFilters.panelsExcluded,
        package: serializePackage(subPlanFilters.package),
        polygon_geom: serializePolygonGeom(subPlanFilters.polygon_geom),
        ...serializeProximity(subPlanFilters.proximity),
      }
    },
  )
  return filtersSerialized
}

// SERIALIZE SUB FUNCTIONS
function serializePolygonGeom(polygonGeom) {
  if (typeof polygonGeom === 'string') {
    return polygonGeom
  } else {
    const polygons =
      serializePolygons((polygonGeom || {}).features || []) || undefined
    return JSON.stringify(polygons)
  }
}
function serializeGeographicalDistribution(geographicalDistribution) {
  return geographicalDistribution || {}
}

function serializeDigitalPanel(isDigital) {
  return isDigital === true || isDigital === false ? isDigital : ''
}

function serializePanelTypes(plan) {
  return plan.types
}

function serializePackage(thePackage) {
  return thePackage || ''
}

export function getFilteredGeoboundaries({ countries, geoboundaries }) {
  const filteredBoundaries = {}
  for (const type in geoboundaries) {
    filteredBoundaries[type] = geoboundaries[type].filter((g) => {
      if (!g.countries) {
        return true
      }
      const countryId = g.countries[0].id
      return countries.some((c) => c.id === countryId)
    })
  }
  return filteredBoundaries
}

function serializeDistance(distance) {
  if (!distance) {
    const meters =
      Math.round((distance * ONE_MILE_TO_METER) / RADIUS_DISTANCE_STEP) *
      RADIUS_DISTANCE_STEP
    return meters
  }
  return 0
}

function serializeGeoboundaries(geoboundaries = {}) {
  const serializedBoundaries = { ...geoboundaries }
  for (const type in serializedBoundaries) {
    if (type === GEO_BOUNDARIES_TYPES.FILE) {
      continue
    }
    const boundariesInType = serializedBoundaries[type]
    const data = boundariesInType.map((boundary) => {
      if (!boundary.type) {
        boundary.type = null
      }
      return boundary
    })
    serializedBoundaries[type] = data.length ? data : []
  }

  return serializedBoundaries
}

function serializeAudiences(filters) {
  const andGroups = (filters.audiences || []).map((a) => a.ids.join(','))
  const orGroups = (filters.audiencesGroups || [])
    .map((a) => a.ids.join(','))
    .join(',')
  const selection = andGroups.concat(orGroups).join('^')
  return {
    profiledata_index_percent:
      filters.audiencesType === 'percent' ? filters.audiencesPercent : null, // 20,
    profile_classes: selection || null,
    profiledata_index__gte:
      selection && filters.audiencesType === 'index'
        ? filters.audiencesIndex
        : null,
    audiencesIndex: filters.audiencesIndex,
    audiencesPercent: filters.audiencesPercent,
    audiencesType: filters.audiencesType,
    audiencesGroups: filters.audiencesGroups || [],
    audiences: filters.audiences || null,
    delivery: filters.delivery || null,
    userAudiencesGroup: filters.userAudiencesGroup || [],
  }
}

function serializeCountries(data) {
  return data || []
}

function serializePolygons(features) {
  return features.map((f) => {
    if (f.properties.radius) {
      return {
        type: 'Feature',
        properties: {
          radius: f.properties.radius,
          units: f.properties.units,
          name: f.properties.name,
          center: f.properties.center,
        },
        geometry: {
          type: 'Point',
          coordinates: f.properties.coordinates,
        },
        id: f.id,
      }
    }
    return f
  })
}

const RADIUS_DISTANCE_STEP = 5
function serializeProximity(proximity) {
  if (!proximity || !proximity.data.length) {
    return undefined
  }
  const categories = proximity.data
    .filter((d) => d.type === PROXIMITY_TYPES.CATEGORIES)
    .map((d) => d.id)
  const chains = proximity.data
    .filter((d) => d.type === PROXIMITY_TYPES.BRANDS)
    .map((d) => d.id)
  const custom = proximity.data
    .filter((d) => d.type === PROXIMITY_TYPES.CUSTOM_POIS)
    .map((d) => d.id)
  const locations = proximity.data
    .filter((d) => d.type === PROXIMITY_TYPES.LOCATIONS)
    .map((d) => d.id)
  const serialized = {
    region_type: 'HerePoi',
    types: {
      categories: categories.length ? categories : undefined,
      chains: chains.length ? chains : undefined,
      custompois_groups: custom.length ? custom : undefined,
      locations: locations.length ? locations : undefined,
    },
    mode: proximity.method,
    distance: proximity.distance,
    operation: proximity.operation,
  }
  if (serialized.mode === 'RADIUS') {
    serialized.distance = serializeDistance(serialized.distance)
  }

  return {
    close_to: serialized,
    meta_proximity: proximity,
  }
}
