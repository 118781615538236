import { getAppMetadata } from '@/plan/metadata/metadata'
import { AppMetadata } from '@workspaces/types/src/metadata.type'

export enum TypeOfMessage {
  Info = 'MESSAGE_INFO',
  Warning = 'MESSAGE_WARNING',
  Error = 'MESSAGE_ERROR',
}

export interface MessageState {
  metadata?: AppMetadata
  show: boolean
  typeOfMessage: TypeOfMessage
  title: string
  msg: string
  action: () => void
}

const appMetadata = getAppMetadata()
export const defaultMessageState: MessageState = {
  metadata: appMetadata,
  show: false,
  typeOfMessage: TypeOfMessage.Info,
  title: 'Unset Message',
  msg: '',
  action: () => {
    return 0
  },
}

export const state: MessageState = {
  ...defaultMessageState,
}
