import { Environment, Metadata, Plan } from '@workspaces/types'
import { CustomGeoboundaryService } from '@workspaces/services'
import { JobInfo } from '@workspaces/types/src/customGeoboundaries.type'
import { sortData } from '@/utils/sort'

export default {
  async getCustomGeoboundaries(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    userId: string,
  ): Promise<Plan.Plan[]> {
    let data = await CustomGeoboundaryService.getCustomGeoboundaries(
      meta,
      environment,
      userId,
    )
    data = sortData<Plan.Plan>(data, { field: 'created_at', order: 'desc' })
    return data
  },
  async getCustomGeoboundary(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    datasetId: string,
  ): Promise<Plan.Plan> {
    const dataset = await CustomGeoboundaryService.getCustomGeoboundary(
      meta,
      environment,
      datasetId,
    )
    const datasetFormatted = {
      ...dataset,
      id: dataset.id,
    }
    return datasetFormatted
  },
  async createCustomGeoboundary(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    userId: string,
    dataset: Plan.Plan,
  ): Promise<string> {
    dataset.created_by = userId
    const datasetId = await CustomGeoboundaryService.createCustomGeoboundary(
      meta,
      environment,
      dataset,
    )
    return datasetId
  },
  async updateCustomGeoboundary(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    datasetId: string,
    dataset: Plan.Plan,
  ) {
    return await CustomGeoboundaryService.updateCustomGeoboundary(
      meta,
      environment,
      datasetId,
      dataset,
    )
  },
  async deleteCustomGeoboundary(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    datasetId: string,
  ) {
    return await CustomGeoboundaryService.deleteCustomGeoboundary(
      meta,
      environment,
      datasetId,
    )
  },
  // FILE CUSTOM GEOBOUNDARIES
  // FILE CUSTOM GEOBOUNDARIES
  // FILE CUSTOM GEOBOUNDARIES
  async getSignedURLUploadFile(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    filename: string,
  ) {
    return await CustomGeoboundaryService.getSignedURLUploadFile(
      meta,
      environment,
      filename,
    )
  },
  async uploadFileWithSignedURL(url: string, file: File) {
    await CustomGeoboundaryService.uploadFileWithSignedURL(url, file)
  },
  async storeFileDataJob(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    fileId: string,
    fileName: string,
  ): Promise<JobInfo> {
    return await CustomGeoboundaryService.storeFileDataJob(
      meta,
      environment,
      fileId,
      fileName,
    )
  },
  async checkStatusStoreFileDataJob(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    jobId: string,
  ): Promise<JobInfo> {
    return await CustomGeoboundaryService.checkStatusStoreFileDataJob(
      meta,
      environment,
      jobId,
    )
  },
  async moveStoredRawDataAndClean(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    fileId: string,
    fileName: string,
  ): Promise<void> {
    return await CustomGeoboundaryService.moveStoredRawDataAndClean(
      meta,
      environment,
      fileId,
      fileName,
    )
  },
  async deleteStoredFileData(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    fileId: string,
  ): Promise<void> {
    return await CustomGeoboundaryService.deleteStoredFileData(
      meta,
      environment,
      fileId,
    )
  },

  async isGeometryForFileIdAvailable(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    fileId: string,
  ): Promise<boolean> {
    const result = await CustomGeoboundaryService.isGeometryForFileIdAvailable(
      meta,
      environment,
      fileId,
    )
    return result
  },
}
