// adapted from here: https://medium.com/javascript-in-plain-english/v-outside-click-writing-vue-directives-for-seamless-interactions-99abdb362813

const EVENTS = {}

function validate(binding) {
  const validObj = binding && typeof binding === 'object'
  const validFn = binding && typeof binding === 'function'
  const validHandler =
    validFn || (validObj && typeof binding.handler === 'function')
  const validExclude =
    validFn ||
    (validObj && binding.exclude ? Array.isArray(binding.exclude) : true)
  if (!validObj && !validFn) {
    // eslint-disable-next-line
    console.error(
      '[clickOutside.js] Invalid binding: value should be a function or an object',
    )
    return false
  }
  if (!validHandler) {
    // eslint-disable-next-line
    console.error(
      '[clickOutside.js] Invalid binding: value.handler should be a function',
    )
    return false
  }
  if (!validExclude) {
    // eslint-disable-next-line
    console.error(
      '[clickOutside.js] Invalid binding: value.exclude should be an array or not be defined',
    )
    return false
  }
  return true
}

function randomKey() {
  return Math.random().toString(36).substr(2)
}

const ClickOutside = {
  bind(el, binding) {
    if (binding.value && binding.value.disabled) {
      return
    }
    if (!validate(binding.value)) {
      return
    }
    const key = `${Object.keys(el.dataset).join()}___${randomKey()}`
    if (el.__clickOutsideKey) {
      document.body.removeEventListener('click', EVENTS[el.__clickOutsideKey])
      document.body.removeEventListener(
        'touchstart',
        EVENTS[el.__clickOutsideKey],
      )
      delete EVENTS[el.__clickOutsideKey]
    }
    el.__clickOutsideKey = key
    EVENTS[key] = (ev) => {
      ev.stopPropagation()
      const handler =
        typeof binding.value === 'object'
          ? binding.value.handler
          : binding.value
      const exclude =
        typeof binding.value === 'object' ? binding.value.exclude || [] : []

      let hasClickedOnExcluded = false
      if (ev.target.className) {
        for (const className of exclude) {
          hasClickedOnExcluded = ev.target.classList.contains(className)
          if (hasClickedOnExcluded) {
            break
          }
        }
      }

      if (el.contains(ev.target) || el === ev.target || hasClickedOnExcluded) {
        return
      }

      handler()
    }

    document.body.addEventListener('click', EVENTS[key])
    document.body.addEventListener('touchstart', EVENTS[key])
  },
  componentUpdated(el, binding) {
    if (binding.value !== binding.oldValue) {
      binding.def.unbind(el)
      binding.def.bind(el, binding)
    }
  },
  unbind(el) {
    const key = el.__clickOutsideKey
    if (key) {
      document.body.removeEventListener('click', EVENTS[key])
      document.body.removeEventListener('touchstart', EVENTS[key])
      delete EVENTS[key]
      delete el.__clickOutsideKey
    }
  },
}

export default ClickOutside
