export enum Role {
  PowerUser = 'power_user',
  Admin = 'admin',
  User = 'user',
  Unknown = 'unknown',
  Anonymous = 'anonymous',
}

export enum PermissionResolver {
  US = 'US',
  EU = 'EU',
  FI = 'FI',
}
