import { FilterAppliedResult } from './plan.type'
import { AssetDataGeoJsonLayer } from './asset.type'
import { Filter } from './filter.type'
import { Metadata } from '@workspaces/types'

export function filterAssetsByCountry(
  assets: AssetDataGeoJsonLayer[],
  filter: number[],
): FilterAppliedResult {
  if (filter === undefined || filter === null) {
    return { assets, filterApplied: false }
  }

  const assetsFiltered = assets.filter((asset) => {
    const { properties } = asset
    if (!properties.country_id) {
      throw new Error(
        'You have active the country filter but the country_id is not present in the asset',
      )
    }
    if (filter.includes(properties.country_id)) {
      return true
    }
    return false
  })

  return { assets: assetsFiltered, filterApplied: true }
}

export function getCountryIdsFromPlan(
  metadata: Metadata.AppMetadata,
  filters: Filter[],
): number[] {
  if (metadata.countries.length === 1) {
    return []
  }

  const countryIds = filters.reduce((acc: number[][], filter) => {
    acc.push(filter.countries)
    return acc
  }, [])
  const countryIdsFlat = countryIds.flat()
  const uniqueCountryIds = [...new Set(countryIdsFlat)]
  return uniqueCountryIds
}
