/** * Icon.vue **/ /* template import */
<template src="./icon-buffer.html"></template>
/* style import */
<style scoped lang="scss" src="./icon-buffer.scss"></style>
<script>
export default {
  name: 'IconBuffer',
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {}
  },
}
</script>
