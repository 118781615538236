import { Metadata, Geoboundary, Environment } from '@workspaces/types'
import { GeoboundaryService } from '@workspaces/services'
import { isMultiCountry } from '@/plan/metadata/metadata.helper'

function sortAlphabeticallyAutoCompleteResult(
  a: Geoboundary.AutoCompleteResult,
  b: Geoboundary.AutoCompleteResult,
) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

export default {
  async getValuesGroupByPattern(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Geoboundary.AutoCompleteGroupParmsRequest,
  ): Promise<Geoboundary.AutoCompleteGroupResult[]> {
    if (!isMultiCountry(meta)) {
      delete queryParams.countries_id
    }

    const data = await GeoboundaryService.getValuesGroupByPattern(
      meta,
      environment,
      queryParams,
    )
    data.forEach((element: Geoboundary.AutoCompleteGroupResult) => {
      element.data.sort(sortAlphabeticallyAutoCompleteResult)
    })
    return data
  },

  async getGeometryByIds(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Geoboundary.GeometryByIdRequest,
  ): Promise<Geoboundary.GeometryByIdResult[]> {
    const data = await GeoboundaryService.getGeometryByIds(
      meta,
      environment,
      queryParams,
    )
    return data
  },

  async getGeometryByNames(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Geoboundary.GeometryByNameRequest,
  ): Promise<Geoboundary.GeometryByNameResult[]> {
    const data = await GeoboundaryService.getGeomertyByName(
      meta,
      environment,
      queryParams,
    )
    return data
  },

  async getGeoboundariesByTypeAndCountry(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Geoboundary.GeoboundariesByTypeAndCountryRequest,
  ): Promise<Geoboundary.GeoboundariesByTypeAndCountryResult[]> {
    const data = await GeoboundaryService.getGeoboundariesByTypeAndCountry(
      meta,
      environment,
      queryParams,
    )
    return data
  },
}
