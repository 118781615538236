export const MODULE_NAME = 'messages'

export const GETTERS = {
  GET_SHOW: 'GET_SHOW',
  GET_TITLE: 'GET_TITLE',
  GET_TYPE_OF_MESSAGE: 'GET_TYPE_OF_MESSAGE',
  GET_MESSAGE: 'GET_MESSAGE',
  GET_ACTION: 'GET_ACTION',
}

export const MUTATIONS = {
  SET_MESSAGE: 'SET_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
}
