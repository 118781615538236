import { getRegionFromGenericRegion } from '../metadata/metadata.helper'
import {
  AssetDataGeoJsonLayer,
  AssetDataGeoJsonLayerRegionsRelations,
} from './asset.type'
import { getAssetDataGeoJsonLayerPropertiesKey } from './asset.helper'
import { FilterAppliedResult } from './plan.type'
import { FeatureCollection } from '@turf/turf'
import booleanIntersects from '@turf/boolean-intersects'
import { findElementInSortedArray } from '../utils/sort'
import GeometryService from '@/services/geometry.service'
import { Environment, Metadata, Geoboundary } from '@workspaces/types'

function getGeoboundariesInFilter(
  filter: Geoboundary.AssetFilterGeoboundaries,
  excludeRegions: boolean,
): Geoboundary.GeoboundaryByTypes[] {
  const geoboundaries: Geoboundary.GeoboundaryByTypes[] = []
  let geoboundaryKey: keyof Geoboundary.AssetFilterGeoboundaries
  for (geoboundaryKey in filter) {
    if (geoboundaryKey === 'region_15') {
      continue
    }
    const ids: number[] = []
    const geoboundaryContent = filter[geoboundaryKey]
    geoboundaryContent?.forEach((element) => {
      if (
        excludeRegions === false &&
        (element.exclude === undefined || element.exclude === false)
      ) {
        ids.push(element.id)
      }
      if (excludeRegions === true && element.exclude === true) {
        ids.push(element.id)
      }
    })
    if (ids.length > 0) {
      const geoboundaryTypeValue = getRegionFromGenericRegion(geoboundaryKey)
      geoboundaries.push({ type: geoboundaryTypeValue.name, ids })
    }
  }
  return geoboundaries
}

function filterAssetsByGeoboundaryZipCodeFile(
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaryContent[] | undefined,
): FilterAppliedResult {
  if (filter === undefined || filter === null || filter.length === 0) {
    return { assets, filterApplied: false }
  }

  const zipCodesNames = new Set<string>()
  filter.forEach((file) => {
    file.entities?.forEach((zipCodeName) => {
      zipCodesNames.add(zipCodeName)
    })
  })

  const assetsFiltered = assets.filter((asset) => {
    const { properties } = asset
    const assetZipCodeName = properties.zip_code_name
    if (!assetZipCodeName) {
      return false
    }
    return zipCodesNames.has(assetZipCodeName)
  })

  return { assets: assetsFiltered, filterApplied: true }
}

function filterAssetsByGeoboundaryRegions(
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaries,
): FilterAppliedResult {
  if (filter === undefined || filter === null) {
    return { assets, filterApplied: false }
  }

  const geoboundaries = getGeoboundariesInFilter(filter, false)
  if (geoboundaries.length === 0) {
    return { assets, filterApplied: false }
  }

  const assetsFiltered = assets.filter((asset) => {
    const { properties } = asset
    for (const geoboundary of geoboundaries) {
      const key: keyof AssetDataGeoJsonLayerRegionsRelations =
        getAssetDataGeoJsonLayerPropertiesKey(
          geoboundary.type.toLocaleLowerCase(),
        )
      const assetRegionId = properties[key]
      if (!assetRegionId) {
        return false
      }
      if (typeof assetRegionId !== 'number') {
        const msg = `🛑 Unexpected error filtering assets by geoboundary. Asset region id ${assetRegionId} is not a number.`
        console.error(msg)
        throw new Error(msg)
      }
      if (geoboundary.ids.includes(assetRegionId)) {
        return true
      }
    }
    return false
  })

  return { assets: assetsFiltered, filterApplied: true }
}

function filterAssetsByExcludedGeoboundaryRegions(
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaries,
): FilterAppliedResult {
  if (filter === undefined || filter === null) {
    return { assets, filterApplied: false }
  }

  const geoboundaries = getGeoboundariesInFilter(filter, true)
  if (geoboundaries.length === 0) {
    return { assets, filterApplied: false }
  }

  const assetsFiltered = assets.filter((asset) => {
    const { properties } = asset
    for (const geoboundary of geoboundaries) {
      const key: keyof AssetDataGeoJsonLayerRegionsRelations =
        getAssetDataGeoJsonLayerPropertiesKey(
          geoboundary.type.toLocaleLowerCase(),
        )
      const assetRegionId = properties[key]
      if (!assetRegionId) {
        return true
      }
      if (typeof assetRegionId !== 'number') {
        const msg = `🛑 Unexpected error filtering assets by geoboundary. Asset region id ${assetRegionId} is not a number.`
        console.error(msg)
        throw new Error(msg)
      }
      if (geoboundary.ids.includes(assetRegionId)) {
        return false
      }
    }
    return true
  })

  return { assets: assetsFiltered, filterApplied: true }
}

export function filterAssetsByCustomGeoboundary(
  assets: AssetDataGeoJsonLayer[],
  filter: FeatureCollection,
  includedInGeometry = true,
): FilterAppliedResult {
  if (filter === undefined || filter === null || filter.features.length === 0) {
    return { assets, filterApplied: false }
  }

  const geoboundaries = filter.features

  const assetsFiltered = assets.filter((asset) => {
    const intersects = geoboundaries.some((geoboundary) => {
      return booleanIntersects(geoboundary, asset)
    })
    if (includedInGeometry) {
      return intersects
    }
    return !intersects
  })

  return { assets: assetsFiltered, filterApplied: true }
}

export function unionOfAssets(
  assets1: AssetDataGeoJsonLayer[],
  assets2: AssetDataGeoJsonLayer[],
): AssetDataGeoJsonLayer[] {
  if (assets1.length === 0) {
    return assets2
  }
  if (assets2.length === 0) {
    return assets1
  }
  if (assets1.length === assets2.length) {
    return assets1
  }

  const assets1Ids = assets1.map((asset) => asset.properties.id)
  const assets2Ids = assets2.map((asset) => asset.properties.id)
  const assets1Sorted = assets1.sort((a, b) =>
    a.properties.id.localeCompare(b.properties.id),
  )
  const assets2Sorted = assets2.sort((a, b) =>
    a.properties.id.localeCompare(b.properties.id),
  )
  const uniqueAssetsIds = new Set([...assets1Ids, ...assets2Ids])
  const uniqueAssetsIdsAsArray = Array.from(uniqueAssetsIds)

  const assets = uniqueAssetsIdsAsArray.map((assetId) => {
    let assetIndex = findElementInSortedArray(
      assetId,
      ['properties', 'id'],
      assets1Sorted,
    )
    if (assetIndex > -1) {
      return assets1Sorted[assetIndex]
    }
    assetIndex = findElementInSortedArray(
      assetId,
      ['properties', 'id'],
      assets2Sorted,
    )
    if (assetIndex === -1) {
      throw new Error(
        `Unexpected error combining assets result at geoboundary filter. Asset id ${assetId} not found in groups provided.`,
      )
    }
    return assets2Sorted[assetIndex]
  })

  return assets
}

export async function filterAssetsByFileGeoboundary(
  environemtentResolver: Environment.EnvironmentResolver,
  metadata: Metadata.AppMetadata,
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaryContent[],
  included = true,
): Promise<FilterAppliedResult> {
  if (!filter || filter.length === 0) {
    return { assets, filterApplied: false }
  }
  let partialAssets: AssetDataGeoJsonLayer[] = []
  let partialFilterApply = false
  // TODO: Se tienen que agrupar todas las geometrías y luego pasar los assets, porque al final hay que pasar por todos los assets y todas las geometrías
  for (let index = 0; index < filter.length; index++) {
    const dataset = filter[index]
    console.debug(
      `                                      Fetching geometry for dataset ${dataset.id}`,
    )
    const fileIds = [dataset.id.toString()]
    const geoms = await GeometryService.getGeometriesCustomFileGeometries(
      metadata,
      environemtentResolver,
      fileIds,
    )
    console.debug(
      `                                      Fetched geometry for dataset ${dataset.id}`,
    )
    const filterCustom: FeatureCollection = {
      type: 'FeatureCollection',
      features: geoms.map((geom) => {
        return {
          type: 'Feature',
          geometry: geom,
          properties: {},
        }
      }),
    }
    console.debug(
      `                                      Filtering assets by geometry for dataset ${dataset.id}`,
    )
    const {
      assets: assetsFilteredByFileCustomGeoboundary,
      filterApplied: customGeoboundaryApplied,
    } = filterAssetsByCustomGeoboundary(assets, filterCustom, included)
    console.debug(
      `                                      Filtered assets by geometry for dataset ${dataset.id}`,
    )

    partialFilterApply = partialFilterApply || customGeoboundaryApplied

    console.debug(
      `                                      Union of partial assets for dataset ${dataset.id}`,
    )
    partialAssets = unionOfAssets(
      partialAssets,
      assetsFilteredByFileCustomGeoboundary,
    )
    console.debug(
      `                                      Union done of partial assets for dataset ${dataset.id}`,
    )
  }

  return {
    assets: partialAssets,
    filterApplied: partialFilterApply,
  } as FilterAppliedResult
}

// TODO: Para no perder la original
export async function filterAssetsByFileGeoboundaryOriginal(
  environemtentResolver: Environment.EnvironmentResolver,
  metadata: Metadata.AppMetadata,
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaryContent[],
  included = true,
): Promise<FilterAppliedResult> {
  if (!filter || filter.length === 0) {
    return { assets, filterApplied: false }
  }
  let partialAssets: AssetDataGeoJsonLayer[] = []
  let partialFilterApply = false
  for (let index = 0; index < filter.length; index++) {
    const dataset = filter[index]
    const fileIds = [dataset.id.toString()]
    const geoms = await GeometryService.getGeometriesCustomFileGeometries(
      metadata,
      environemtentResolver,
      fileIds,
    )
    const filterCustom: FeatureCollection = {
      type: 'FeatureCollection',
      features: geoms.map((geom) => {
        return {
          type: 'Feature',
          geometry: geom,
          properties: {},
        }
      }),
    }

    const {
      assets: assetsFilteredByFileCustomGeoboundary,
      filterApplied: customGeoboundaryApplied,
    } = filterAssetsByCustomGeoboundary(assets, filterCustom, included)

    partialFilterApply = partialFilterApply || customGeoboundaryApplied

    partialAssets = unionOfAssets(
      partialAssets,
      assetsFilteredByFileCustomGeoboundary,
    )
  }

  return {
    assets: partialAssets,
    filterApplied: partialFilterApply,
  } as FilterAppliedResult
}

export async function filterAssetsByGeoboundaryRegion18(
  environmentResolver: Environment.EnvironmentResolver,
  metadata: Metadata.AppMetadata,
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaries,
): Promise<FilterAppliedResult> {
  const region18 = filter.region_18
  if (!Array.isArray(region18) || !region18.length) {
    return { assets: [], filterApplied: false }
  }
  const fileGeoboundaryPartialFilterResult: FilterAppliedResult =
    await filterAssetsByFileGeoboundary(
      environmentResolver,
      metadata,
      assets,
      region18,
    )
  return fileGeoboundaryPartialFilterResult
}

export function filterAssetsByGeoboundary(
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaries,
  filterCustom: FeatureCollection,
): FilterAppliedResult {
  const filterForGeoboundaries = { ...filter }
  // Remove geoboundary filters that do not resolve directly to a region
  delete filterForGeoboundaries.region_15
  delete filterForGeoboundaries.region_16
  delete filterForGeoboundaries.region_17
  delete filterForGeoboundaries.region_18

  const { assets: assetsFilteredByRegions, filterApplied: regionsApplied } =
    filterAssetsByGeoboundaryRegions(assets, filterForGeoboundaries)

  const {
    assets: assetsFilteredByZipCodeFile,
    filterApplied: zipCodeFileApplied,
  } = filterAssetsByGeoboundaryZipCodeFile(assets, filter.region_15)

  const {
    assets: assetsFilteredBycustomGeoboundary,
    filterApplied: customGeoboundaryApplied,
  } = filterAssetsByCustomGeoboundary(assets, filterCustom)

  const assetsFilteredByRegionsValidated = regionsApplied
    ? assetsFilteredByRegions
    : []
  const assetsFilteredByZipCodeFileValidated = zipCodeFileApplied
    ? assetsFilteredByZipCodeFile
    : []
  const assetsFilteredByCustomGeoboundaryValidated = customGeoboundaryApplied
    ? assetsFilteredBycustomGeoboundary
    : []

  const geoboundayFilterIsApplied =
    regionsApplied || zipCodeFileApplied || customGeoboundaryApplied

  let uniqueUnionOfAssetsFiltered = unionOfAssets(
    geoboundayFilterIsApplied ? assetsFilteredByRegionsValidated : assets,
    assetsFilteredByZipCodeFileValidated,
  )
  uniqueUnionOfAssetsFiltered = unionOfAssets(
    uniqueUnionOfAssetsFiltered,
    assetsFilteredByCustomGeoboundaryValidated,
  )

  const filterApplied =
    regionsApplied || zipCodeFileApplied || customGeoboundaryApplied

  if (!filterApplied) {
    return { assets: [], filterApplied }
  }

  return {
    assets: uniqueUnionOfAssetsFiltered,
    filterApplied,
  }
}

export function filterAssetsByExcludedGeoboundary(
  assets: AssetDataGeoJsonLayer[],
  filter: Geoboundary.AssetFilterGeoboundaries,
): FilterAppliedResult {
  const {
    assets: assetsFilteredByExcludedRegions,
    filterApplied: excludedRegionsApplied,
  } = filterAssetsByExcludedGeoboundaryRegions(assets, filter)

  if (!excludedRegionsApplied) {
    return { assets, filterApplied: excludedRegionsApplied }
  }

  return {
    assets: assetsFilteredByExcludedRegions,
    filterApplied: excludedRegionsApplied,
  }
}

export function removeExcludedAssets(
  assets: AssetDataGeoJsonLayer[],
  excludedAssets: AssetDataGeoJsonLayer[],
): AssetDataGeoJsonLayer[] {
  const mapOfExcludedAssets = new Map<string, AssetDataGeoJsonLayer>()
  excludedAssets.forEach((asset) => {
    mapOfExcludedAssets.set(asset.properties.id, asset)
  })
  const assetsFiltered = assets.filter((asset) => {
    return !mapOfExcludedAssets.has(asset.properties.id)
  })
  return assetsFiltered
}
