import { Environment, Metadata, Poi } from '@workspaces/types'
import { PoiService } from '@workspaces/services'

export default {
  async filterAssetsByPois(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    filter: Poi.FilterByPoisParamsRequest,
    countryIds: number[],
    assetsIds: string[],
  ): Promise<string[]> {
    const data: string[] = await PoiService.filterAssetsByPois(
      meta,
      environment,
      filter,
      countryIds,
      assetsIds,
    )
    return data
  },
}
