export function isNonEmptyArray(arr: any[]): boolean {
  return Array.isArray(arr) && arr.length > 0
}

export function areEquals<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}
