import { Metadata } from '@workspaces/types'
import { getFlavour } from '../fetcher/metadataFetcher'

export const getAppMetadata = (): Metadata.AppMetadata => getFlavour()

export const getAppFlavour = (): string => getAppMetadata().flavour

export const getAppMetadataLanguages = (): Metadata.Languages =>
  getAppMetadata().languages

export const getAppMetadataLocalStorageKeys = (): Metadata.LocalStorageKeys =>
  getAppMetadata().local_storage_keys

export const isMultiCountry = (): boolean => {
  return getAppMetadata().plan_filters.widget_countries.enable
}
