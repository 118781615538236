function getPropertyValueFromObject<T>(
  object: T,
  fields: string[],
): string | number {
  let value = object
  for (const field of fields) {
    value = value[field as keyof T] as never
  }
  if (typeof value === 'string') {
    return value as string
  }
  if (typeof value === 'number') {
    return value as number
  }

  throw new Error(
    `Invalid type for value: ${value}. Expected string or number for path: ${fields} in object: ${object}`,
  )
}

export function findElementInSortedArray<T>(
  value: string | number,
  fields: string[],
  data: T[],
  lowerIndex = 0,
  higherIndex = data.length - 1,
): number {
  if (data.length === 0) {
    return -1
  }
  if (higherIndex < lowerIndex) {
    return -1
  }
  const middleIndex = Math.floor((lowerIndex + higherIndex) / 2)
  const objectPropertyValue = getPropertyValueFromObject(
    data[middleIndex],
    fields,
  )
  if (objectPropertyValue === value) {
    return middleIndex
  }
  if (objectPropertyValue > value) {
    return findElementInSortedArray(
      value,
      fields,
      data,
      lowerIndex,
      middleIndex - 1,
    )
  }
  return findElementInSortedArray(
    value,
    fields,
    data,
    middleIndex + 1,
    higherIndex,
  )
}
