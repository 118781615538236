/* eslint-disable no-console */
import api from './api'
import * as jwtDecode from 'jwt-decode'
import { getInstance as getAuth0ServiceInstance } from '@/auth/index'
import { getAppMetadata } from '@/plan/metadata/metadata'
import AuditService from '@/services/audit/audit.service'
import { Audit } from '@workspaces/types'
import { FlavourLocalStorageKey } from '@/plan/metadata/metadata.constant'
import {
  getTestingValuesFromLocalStorage,
  setTestingInfoInLocalStorage,
} from '@/helpers/testing/testing.helper'
import { CacheManager, AuthManager } from '@workspaces/services'
import BrowserEnvironmentResolver from '@/helpers/environment.helper'
import BrowserCartoAuthorizer from '@/auth/carto-auth'
import { checkIfUserHasAgency } from '@/helpers/agency.helper'
import { initializeUserPermissionManager } from '@/helpers/auth-browser.helper'
import CartoMetricEvents from '@/services/carto-metric-event.service'

export const AUTHO_CARTO_TOKEN = '@@auth0spajs@@'
export const AUTH0_REDIRECT_URI = `${window.location.origin}/login`
export const AUTH_LOGOUT = `${window.location.origin}/logout`
export const NO_TOKEN_FOUND = 'No token found in local storage'
const SESSION_STORAGE_KEY_FOR_FIRST_LOGIN = 'firstLogin'
const FIRST_LOGIN_POSITIVE_VALUE = 'p4iugpt4i5h9834iuhh349hfj'

export default {
  refreshGapMs: 5000,
  refreshTimeoutId: null,
  getAuthCarto3FromLocalStorage: () => {
    const numberOfLocalStorageKeys = localStorage.length
    for (let i = 0; i < numberOfLocalStorageKeys; i++) {
      const key = localStorage.key(i)
      if (key.startsWith(AUTHO_CARTO_TOKEN)) {
        const content = JSON.parse(localStorage.getItem(key))
        return content.body.access_token
      }
    }
    return NO_TOKEN_FOUND
  },
  getAuth: () =>
    JSON.parse(
      localStorage.getItem(getAppMetadata().local_storage_keys.auth) || '{}',
    ),
  setAuth: (auth) =>
    localStorage.setItem(
      getAppMetadata().local_storage_keys.auth,
      JSON.stringify(auth),
    ),
  removeAuth: () =>
    localStorage.removeItem(getAppMetadata().local_storage_keys.auth),
  removeAllApplicationData() {
    const isFisrtLogin = this.isFirstLogin()
    const flavour = localStorage.getItem(FlavourLocalStorageKey)
    const testingValues = getTestingValuesFromLocalStorage()
    // Delete all local storage content
    localStorage.clear()
    sessionStorage.clear()
    // Delete all cookies
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }

    if (isFisrtLogin) {
      this.setFirstLogin(true)
    }
    if (flavour) {
      localStorage.setItem(FlavourLocalStorageKey, flavour)
    }
    if (testingValues) {
      setTestingInfoInLocalStorage(testingValues)
    }
  },
  async getUserInfo(accessToken) {
    const reqHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }
    const reponse = await fetch('https://accounts.app.carto.com/users/me', {
      method: 'GET',
      cache: 'no-cache',
      headers: reqHeaders,
    })

    return await reponse.json()
  },
  isWrongToken(token) {
    const tokenInfo = jwtDecode(token)
    return !tokenInfo['http://app.carto.com/account_id']
  },
  setFirstLogin(firstLogin) {
    if (firstLogin) {
      localStorage.setItem(
        SESSION_STORAGE_KEY_FOR_FIRST_LOGIN,
        FIRST_LOGIN_POSITIVE_VALUE,
      )
    } else {
      localStorage.removeItem(SESSION_STORAGE_KEY_FOR_FIRST_LOGIN)
    }
  },
  isFirstLogin() {
    const firstLoginValue = localStorage.getItem(
      SESSION_STORAGE_KEY_FOR_FIRST_LOGIN,
    )
    return firstLoginValue === FIRST_LOGIN_POSITIVE_VALUE
  },
  async login(query, avoidRefresh = false) {
    console.debug('👩🏻 AuthService - login', { query, avoidRefresh })
    const accessTokenCartoV3 = this.getAuthCarto3FromLocalStorage()
    // console.debug('AuthService - Local storage token: ', accessTokenCartoV3)
    if (accessTokenCartoV3 && accessTokenCartoV3 !== NO_TOKEN_FOUND) {
      // console.debug('👩🏻 AuthService - We have token')
      const auth0ServiceInstance = getAuth0ServiceInstance()
      auth0ServiceInstance.setAccessTokenCartoV3(accessTokenCartoV3)
      if (!this.isLoggedIn()) {
        console.debug('👩🏻 AuthService - Carto3 token expired')
        auth0ServiceInstance.logout()
        this.removeAllApplicationData()
        return
      }
      // In case user had an old token without organization
      // Check if token has organization
      if (this.isWrongToken(accessTokenCartoV3)) {
        this.setFirstLogin(true)
        // Join to the organization
        console.debug(
          '👩🏻 AuthService - Token without organization, so we need to join user to organization',
        )
        await this.joinInviteUserToOrganization(accessTokenCartoV3)
        // log out
        console.debug(
          '👩🏻 AuthService - Log out and clear session in the browser',
        )
        auth0ServiceInstance.logout()
        this.removeAllApplicationData()
        // login one more time
        console.debug(
          '👩🏻 AuthService - Log in to get a fresh token with organization',
        )
        auth0ServiceInstance.loginWithRedirect()
      } else {
        console.debug('👩🏻 AuthService - User already joined to organization')
        const authManager = AuthManager.getInstance()
        authManager.init(new BrowserCartoAuthorizer())
        const environment = BrowserEnvironmentResolver.getInstance()
        const metadata = getAppMetadata()
        await checkIfUserHasAgency(
          metadata,
          environment,
          authManager.getToken(),
        )
        AuditService.createEvent(metadata, environment, {
          type: Audit.AuditEventType.Login,
        })
        CartoMetricEvents.sendLoginEvent(getAppMetadata(), environment)

        const cacheManager = CacheManager.getInstance()
        await cacheManager.init(environment, authManager, metadata)
        console.debug('👉 Cache info: ', cacheManager.getCacheInfo())

        await initializeUserPermissionManager()
      }
    } else {
      console.debug(
        '👩🏻 AuthService - No token found, so the first thing is to cleanup all data application',
      )
      this.removeAllApplicationData()
      console.debug('👩🏻 AuthService - Redirecting to login')
      if (!window.location.href.startsWith(AUTH0_REDIRECT_URI)) {
        console.debug('👩🏻 AuthService - Login redirection')
        window.location.href = AUTH0_REDIRECT_URI
        return
      }
      console.debug('👩🏻 AuthService - Already on login page')
    }

    // Legacy token management for api_v2
    /* let local = this.getAuth()
    let auth

    if (query) {
      const decoded = jwtDecode(query.access)
      auth = {
        id: decoded.user_id,
        is_superuser: decoded.is_superuser || false,
        is_public: decoded.is_public || false,
        scopes: decoded.scopes || [],
        countries: decoded.countries,
        expireTime: parseInt(decoded.exp) * 1000,
        token: query.access,
        refresh: query.refresh || local.refresh,
      }
    }

    local = auth || {
      id: local.id,
      is_superuser: local.is_superuser || false,
      is_public: local.is_public || false,
      scopes: local.scopes || [],
      countries: local.countries,
      expireTime: parseInt(local.expireTime),
      token: local.token,
      refresh: local.refresh,
    }

    if (!local.token || (local.expireTime && this.isExpired(local))) {
      return
    }

    this.setAuth(local)

    setToken(local.token)
    */

    if (avoidRefresh) {
      console.debug('👩🏻 AuthService - Avoidding token refreshing')
      return
    }
    console.debug(
      '👩🏻 AuthService - We should take into accunt refreshing token',
    )
    /*
    const refreshTime = local.expireTime - this.refreshGapMs
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId)
    }
    this.refreshTimeoutId = setTimeout(
      () => this.refreshWithApiCall(),
      refreshTime - Date.now(),
    ) */
  },
  async joinInviteUserToOrganization(accessToken) {
    console.debug('👩🏻 AuthService - Joining to the organization ...')
    const user = await this.getUserInfo(accessToken)
    const reqHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }

    const response = await fetch(
      'https://accounts.app.carto.com/accounts/join',
      {
        method: 'POST',
        cache: 'no-cache',
        headers: reqHeaders,
        body: JSON.stringify({ sso: true }),
      },
    )
    const invitationToken = await response.json()

    console.debug('👩🏻 AuthService - invitationToken', invitationToken.token)

    console.debug('👩🏻 AuthService - Invite user ...', user)
    await fetch('https://accounts.app.carto.com/accounts/invite/join', {
      method: 'POST',
      cache: 'no-cache',
      headers: reqHeaders,
      body: JSON.stringify({
        country: 'US', // FIXME
        first_name: user.name,
        job_title: 'Viewer',
        last_name: user.name,
        marketing_consent: false,
        token: invitationToken.token,
      }),
    })
    console.debug('👩🏻 AuthService - Joining to the organization: DONE')
  },
  /* async retrieveBackendApiKey(cartoV3Token, redirectUri) {
    try {
      const params = { cartoV3Token, redirect_uri: redirectUri }
      const response = await api.post('/v2/auth/login', params)
      return response
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[authService.js] Could not get backend API KEY', error)
    }
  }, */
  async refreshWithApiCall() {
    console.debug(
      '👩🏻 AuthService - Calling to refresh Token, but doing nothing',
    )
    /* const auth = this.getAuth()
    try {
      const res = await api.post('/v1/token/refresh/', {
        refresh: auth.refresh,
      })
      const newAuth = res.data
      newAuth.access = `Bearer ${newAuth.access}`
      this.login(newAuth)
    } catch (err) {
      // eslint-disable-next-line
      console.error('[authService.js] Error in refresh token call', err)
    } */
  },
  logout(avoidRefresh = false) {
    console.debug('👩🏻 AuthService - logout', { avoidRefresh })
    const auth0ServiceInstance = getAuth0ServiceInstance()
    auth0ServiceInstance.logout()
    this.removeAllApplicationData()
    if (avoidRefresh) {
      this.removeAuth()
      return
    }

    clearTimeout(this.refreshTimeoutId)
    const logoutUrl = this.getLogoutURL()
    this.removeAuth()
    window.location = logoutUrl
  },
  getAuthCallback() {
    return window.location.hostname === 'localhost'
      ? `${window.location.protocol}//localhost:${window.location.port}/login`
      : process.env.VUE_APP_AUTH_CALLBACK
  },
  getLogoutURL() {
    return AUTH_LOGOUT
  },
  getScopes() {
    const local = this.getAuth()
    return local.scopes
  },
  // TODO: SC-381014: Obtain is admin from Userpermissions.
  // This should only be used for carto role in any case
  isAdmin() {
    // const local = this.getAuth()
    // return local.is_superuser
    const auth0Instance = getAuth0ServiceInstance()
    if (auth0Instance) {
      return auth0Instance.isAdmin()
    }
    return false
  },
  isPowerUser() {
    const auth0Instance = getAuth0ServiceInstance()
    if (auth0Instance) {
      return auth0Instance.isPowerUser()
    }
    return false
  },
  isRegularUser() {
    return !this.isAdmin() && !this.isPowerUser()
  },
  isLoggedIn() {
    const token = this.getAuthCarto3FromLocalStorage()
    if (!token || token === NO_TOKEN_FOUND) {
      return false
    }
    const tokenInfo = jwtDecode(token)
    const expireTime = parseInt(tokenInfo.exp)
    const currentSeconds = Date.now() / 1000
    const isExpired = currentSeconds - expireTime > 0
    console.debug('👩🏻 AuthService - isExpired: ', isExpired)
    /* if (!isExpired) {
      console.debug('AuthService - forzamos a que el token esté expirado para que nos haga un logout')
      return false
    } */
    return token && !isExpired
  },
  // TODO: Borrar
  isExpired(token) {
    token = token || this.getAuth()
    const expireTime = parseInt(token.expireTime)
    return Date.now() - expireTime > this.refreshGapMs
  },
  async getPublicToken(planId, password) {
    // const res = await api.post('/v1/token/public/', { password, scopes: [`plan:${planId}`] })
    const res = await api.post('/v2/auth/tokens/public/', { password, planId })
    return res.data
  },
  async shortTokenToLongToken(shortToken, password) {
    const res = await api.get(
      `/v2/auth/tokens/public/${shortToken}?password=${password || ''}`,
    )
    return res.data
  },
}
