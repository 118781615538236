export enum StorageDestination {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
}

export function setValueToStorage(
  destination: StorageDestination,
  key: string,
  value: string,
): void {
  const encoder = new TextEncoder()
  const contentBytes = encoder.encode(value)
  const valueAsString = contentBytes.join(',')

  window[destination].setItem(key, valueAsString)
}

export function getValueFromStorage(
  destination: StorageDestination,
  key: string,
): string | null {
  const contentBytes = window[destination].getItem(key)
  if (!contentBytes) {
    return null
  }

  const valueAsArray = contentBytes.split(',').map(Number)
  const uint8Array = new Uint8Array(valueAsArray)
  const decoder = new TextDecoder()
  const valueAsString = decoder.decode(uint8Array)
  return valueAsString
}

export function removeValueFromStorage(
  destination: StorageDestination,
  key: string,
): void {
  window[destination].removeItem(key)
}
