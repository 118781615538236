import Vue from 'vue'
import VueI18n from 'vue-i18n'

import {
  getAppFlavour,
  getAppMetadataLanguages,
  getAppMetadataLocalStorageKeys,
} from './plan/metadata/metadata'
import { APP_FLAVOURS } from './constants'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const appFlavour = getAppFlavour()
  let locales = {}
  if (appFlavour === APP_FLAVOURS.US) {
    locales = require.context('./locales/US', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  } else if (appFlavour === APP_FLAVOURS.FI) {
    locales = require.context('./locales/FI', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  } else {
    locales = require.context('./locales/EU', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  }
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function loadLocale() {
  return (
    window.localStorage.getItem(getAppMetadataLocalStorageKeys().lang) ||
    process.env.VUE_APP_I18N_LOCALE ||
    getAppMetadataLanguages().options[0].locale ||
    getAppMetadataLanguages().options[0].id ||
    'en'
  )
}

export default new VueI18n({
  locale: loadLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
})
