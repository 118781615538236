import { getInstance as getAuth0ServiceInstance } from '@/auth/index'
import BrowserEnvironmentResolver from './environment.helper'
import { getCartoMeInfo } from './carto.helper'
import { getPresetCountriesForUser } from './country.helper'
import { getAppMetadata } from '@/plan/metadata/metadata'
import UserPermissionManager from './permissions/permissions.manager'
import { getRole } from './auth.helper'
import { Auth, Metadata } from '@workspaces/types'
import { isPackagagesFeatureEnabled } from '@/plan/metadata/metadata.helper'
import { getUserAgencyId } from './agency.helper'
import { ADMIN_AGENCY } from '@/constants'

async function getRoleFromAuth0(
  metadata: Metadata.AppMetadata,
): Promise<Auth.Role> {
  console.debug('👩🏻 AuthService - Getting role from Auht0')
  const auth0Instance = getAuth0ServiceInstance()
  const carto3Token = auth0Instance.getAccessTokenCartoV3()
  const agencyId = await getUserAgencyId(
    metadata,
    BrowserEnvironmentResolver.getInstance(),
    carto3Token,
    true,
  )
  if (agencyId) {
    if (agencyId === ADMIN_AGENCY) {
      return Auth.Role.Admin
    }
    return Auth.Role.User
  }
  return Auth.Role.Unknown
}

export async function initializeUserPermissionManager(): Promise<void> {
  const metadata = getAppMetadata()
  const auth0ServiceInstance = getAuth0ServiceInstance()
  let userName = Auth.Role.Anonymous
  let userRole = Auth.Role.Anonymous
  if (!auth0ServiceInstance) {
    console.debug(
      '👩🏻 AuthService - User not authenticated. Suppose we are in shared plan',
    )
  } else {
    const cartoMe = await getCartoMeInfo(
      BrowserEnvironmentResolver.getInstance(),
      auth0ServiceInstance.getAccessTokenCartoV3(),
    )

    const countryIds = await getPresetCountriesForUser(
      getAppMetadata(),
      BrowserEnvironmentResolver.getInstance(),
      cartoMe,
    )
    auth0ServiceInstance.setUserCountries(countryIds)
    console.debug('🌎 User countries: ', countryIds)

    if (isPackagagesFeatureEnabled(metadata)) {
      userRole = await getRoleFromAuth0(metadata)
    } else {
      userRole = getRole(metadata, cartoMe)
    }

    auth0ServiceInstance.setRole(userRole)
    userName = auth0ServiceInstance.getUser()
    console.debug(`👩🏻 AuthService - User ${userName} with role ${userRole}`)
  }
  UserPermissionManager.initialize({ name: userName, role: userRole })
}
