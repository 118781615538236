import { state } from './state'
import { mutations } from './mutations'
import { getters } from './getters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}

export {
  MODULE_NAME as MODULE_NAME_MESSAGES,
  GETTERS as GETTERS_MESSAGES,
  MUTATIONS as MUTATIONS_MESSAGES,
} from './messages.constants'

export { TypeOfMessage } from './state'
