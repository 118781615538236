/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextLayer } from '@deck.gl/layers'
import { WebMercatorViewport } from '@deck.gl/core'
import layerService from '@/layers/layerService'
import { ASSETS_IDS_LAYER } from '@/layers/constant'
import { isNonEmptyArray } from '@/utils/arrayUtils'

function getViewportBounds(viewState) {
  const viewport = new WebMercatorViewport(viewState)
  const topLeft = viewport.unproject([0, 0])
  const bottomRight = viewport.unproject([viewport.width, viewport.height])

  return {
    minX: topLeft[0],
    maxX: bottomRight[0],
    minY: bottomRight[1],
    maxY: topLeft[1],
  }
}

function filterDataInViewport(data, viewState) {
  const bounds = getViewportBounds(viewState)

  return data.filter((d) => {
    const [x, y] = d.geometry.coordinates
    return (
      x >= bounds.minX &&
      x <= bounds.maxX &&
      y >= bounds.minY &&
      y <= bounds.maxY
    )
  })
}

function calculateCellSize(zoomLevel, zoomLayerForDeclustering) {
  // Definir un tamaño base para la celda
  const baseCellSize = 0.025 // Tamaño en unidades de coordenadas del mapa

  // Ajustar el tamaño de la celda basado en el zoom
  // Aumenta el tamaño de la celda a medida que disminuye el zoom
  // y lo reduce a medida que aumenta el zoom.
  const finalZoomLevel = zoomLevel - zoomLayerForDeclustering
  const den = Math.pow(2, finalZoomLevel)
  const cellSize = baseCellSize / den
  // console.debug({ cellSize, den, finalZoomLevel, zoomLevel })
  return cellSize
}

function createGrid(data, zoomLevel, zoomLayerForDeclustering) {
  const cellSize = calculateCellSize(zoomLevel, zoomLayerForDeclustering)
  const grid = {}

  data.forEach((d) => {
    const [x, y] = d.geometry.coordinates
    const gridX = Math.floor(x / cellSize)
    const gridY = Math.floor(y / cellSize)
    const key = `${gridX}-${gridY}`

    if (!grid[key]) {
      grid[key] = []
    }

    grid[key].push(d)
  })

  return grid
}

function formatLayerTagForOneId(ids) {
  const idsCount = ids.length - 1
  let formattedTag = `${ids[0]}`
  if (idsCount > 0) {
    formattedTag += `(+${idsCount})`
  }
  return formattedTag
}

function formatLayerTag(ids, elementsPerLine, maxLines) {
  let formattedTag = ''
  let lineCount = 0
  const idsCount = ids.length

  for (let index = 0; index < idsCount; index++) {
    if (index > 0 && index % elementsPerLine === 0) {
      formattedTag += '\n'
      lineCount++
    }
    if (lineCount >= maxLines) {
      formattedTag += '...+' + (idsCount - index)
      break
    }
    formattedTag += ids[index]
    if (index < idsCount - 1) {
      formattedTag += ','
    }
  }

  return formattedTag
}

function filterData(grid, zoomLevel, showAllAssetsIds) {
  const filteredData = []

  Object.keys(grid).forEach((key) => {
    const cellData = grid[key]

    if (cellData.length > 0) {
      const asset = cellData[cellData.length - 1]
      if (zoomLevel < 17.5) {
        if (showAllAssetsIds) {
          const ids = cellData
            .reverse()
            .map((d) => d.properties.internal_panel_id)
          asset.properties.layerTag = formatLayerTagForOneId(ids)
        } else {
          asset.properties.layerTag = `${asset.properties.internal_panel_id}`
        }
      } else {
        const ids = cellData
          .reverse()
          .map((d) => d.properties.internal_panel_id)
        asset.properties.layerTag = formatLayerTag(ids, 3, 3)
      }
      filteredData.push(asset)
    }
  })

  return filteredData
}

const textLayerProps = {
  id: ASSETS_IDS_LAYER,
  layerType: TextLayer,
  getPosition: (d) => d.geometry.coordinates,
  getText: (d) => d.properties.layerTag,
  getSize: 10,
  getPixelOffset: [0, 15],
  getTextAnchor: 'middle',
  getAlignmentBaseline: 'top',
  background: true,
  backgroundPadding: [5, 5, 5, 5],
  getBackgroundColor: [255, 255, 255, 50],
  getBorderColor: [255, 255, 255, 100],
  getBorderWidth: 2,
}

export function updateAssetIdLayer(params) {
  const { viewState, zoomLayerForDeclustering, assets } = params
  if (assets !== undefined && !Array.isArray(assets)) {
    throw new Error('If paramater assets is provided must be an array')
  }
  console.time('updateAssetIdLayer')
  const currentZoom = viewState.zoom
  if (currentZoom < zoomLayerForDeclustering) {
    layerService.removeLayer(ASSETS_IDS_LAYER)
    return
  }
  const allAssets = assets ?? layerService.getAssetsPaintendOnMap()
  const visibleData = filterDataInViewport(allAssets, viewState)
  console.debug(
    `Plan assets: ${allAssets.length} VS Visible assets: ${visibleData.length}`,
  )
  const grid = createGrid(visibleData, currentZoom, zoomLayerForDeclustering)
  const filteredData = filterData(grid, currentZoom, true)
  console.debug(
    `Visible assets after resolving collisions: ${filteredData.length}`,
  )

  const layerProps = {
    ...textLayerProps,
    data: filteredData,
    updateTriggers: {
      data: viewState.zoom,
    },
  }
  layerService.addLayer(layerProps)

  console.timeEnd('updateAssetIdLayer')
}
