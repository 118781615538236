const KEY_TMP_BASEMAP_ID = 'tmpBaseMapId'

export function saveNewBaseMapSessionStorage(baseMapId: string) {
  sessionStorage.setItem(KEY_TMP_BASEMAP_ID, baseMapId)
}

export function getAndRemoveNewBaseMapSessionStorage(): string | null {
  const value = sessionStorage.getItem(KEY_TMP_BASEMAP_ID)
  sessionStorage.removeItem(KEY_TMP_BASEMAP_ID)
  return value
}
