import { Auth } from '@workspaces/types'
import { UserInformation } from '../permissions.manager.type'

abstract class ResolverBasic {
  protected userInformation: UserInformation

  constructor(userInformation: UserInformation) {
    this.userInformation = userInformation
  }

  protected getRole(): Auth.Role {
    if (this.userInformation.role === Auth.Role.Unknown) {
      throw new Error(
        'Trying to use role unknown when deciding if user can do an action',
      )
    }
    return this.userInformation.role
    // return Auth.Role.Admin
  }

  protected isAdmin(): boolean {
    return this.getRole() === Auth.Role.Admin
  }

  protected isAnonymous(): boolean {
    return this.getRole() === Auth.Role.Anonymous
  }

  protected getName(): string {
    return this.userInformation.name
  }

  protected isOwnerOrAdmin(user: string): boolean {
    return this.getName() === user || this.getRole() === Auth.Role.Admin
  }
}

export default ResolverBasic
