import { GETTERS } from './messages.constants'
import { MessageState } from './state'

export const getters = {
  [GETTERS.GET_SHOW]: (state: MessageState) => {
    return state.show
  },
  [GETTERS.GET_TITLE]: (state: MessageState) => {
    return state.title
  },
  [GETTERS.GET_TYPE_OF_MESSAGE]: (state: MessageState) => {
    return state.typeOfMessage
  },
  [GETTERS.GET_MESSAGE]: (state: MessageState) => {
    return state.msg
  },
}
