import { Metadata, Environment, Carto } from '@workspaces/types'
import { CartoMetricEventService } from '@workspaces/services'
import { getISO2Flavour } from '@/plan/metadata/metadata.helper'
import { getInstance as getAuth0ServiceIntance } from '@/auth/index'

async function sendCartoMetricEvent(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  eventType: Carto.MetricEventType,
): Promise<void> {
  if (environment.isTesting()) {
    return
  }

  if (environment.getEnvironment() !== Environment.Environment.Production) {
    return
  }

  const auth0Instance = getAuth0ServiceIntance()
  const userId = auth0Instance.getCartoUser()
  const accountId = auth0Instance.getAccountId()

  const flavour = getISO2Flavour(meta)
  let eventName = eventType.toString()
  eventName = eventName.replace(Carto.METRIC_EVENT_PATTERN_TO_REPLACE, flavour)
  const event: Carto.MetricEvent = {
    event_type: eventName,
    timestamp: new Date().toISOString(),
    payload: {
      id: accountId,
      user_id: userId, // 'oidc|clear-channel-international-oidc|zmAweTVJFr5HQhpLuv0LyBe12EWFYvp9y_2GSS-Zcng'
      template: eventName,
      metadata_org: `Radarview${flavour}`,
    },
  }

  await CartoMetricEventService.sendCartoMetricEvent(meta, environment, event)
}

export default {
  async sendLoginEvent(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
  ): Promise<void> {
    await sendCartoMetricEvent(meta, environment, Carto.MetricEventType.Login)
  },

  async sendSolutionBusinessValueEvent(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
  ): Promise<void> {
    await sendCartoMetricEvent(
      meta,
      environment,
      Carto.MetricEventType.SolutionBusinessValue,
    )
  },
}
