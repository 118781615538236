/* eslint-disable @typescript-eslint/no-explicit-any */
import { Filter } from './filter.type'
import { getAppMetadata } from '../metadata/metadata'
import { AssetDataGeoJsonLayer } from './asset.type'
import { FilterAppliedResult } from './plan.type'
import { FilterByPoisParamsRequestData, PoisFilter } from './pois.type'
import { milesToMeter } from '../utils/metersToPixels'
import { Environment, Metadata, Poi } from '@workspaces/types'
import PoiService from '@/services/poi.service'

function getPOIsTypeFromDataRequest(
  type: Poi.POIsClasification,
  data: FilterByPoisParamsRequestData[],
): string[] {
  const types = data.filter((d) => d.type === type)
  if (types.length === 0) {
    return []
  }

  const formattedTypes = types.map((t) => t.id)
  return formattedTypes
}

function getCategoriesFromDataRequest(
  data: FilterByPoisParamsRequestData[],
): string[] {
  const categories = data.filter(
    (d) => d.type === Poi.POIsClasification.Category,
  )
  if (categories.length === 0) {
    return []
  }

  const formattedCategories = categories.map((c) => c.id)
  return formattedCategories
}

function getBrandsFromDataRequest(
  data: FilterByPoisParamsRequestData[],
): string[] {
  const categories = data.filter((d) => d.type === Poi.POIsClasification.Brand)
  if (categories.length === 0) {
    return []
  }

  const formattedCategories = categories.map((c) => c.id)
  return formattedCategories
}

function getTagsFromDataRequest(
  data: FilterByPoisParamsRequestData[],
): string[] {
  const categories = data.filter((d) => d.type === Poi.POIsClasification.Tags)
  if (categories.length === 0) {
    return []
  }

  const formattedCategories = categories.map((c) => c.id)
  return formattedCategories
}

function getLocationsFromDataRequest(
  data: FilterByPoisParamsRequestData[],
): string[] {
  const locations = data.filter(
    (d) => d.type === Poi.POIsClasification.Locations,
  )
  if (locations.length === 0) {
    return []
  }

  const formattedCategories = locations.map((c) => c.name)
  return formattedCategories
}

export function getCustomPOIsFromDataRequest(
  data: FilterByPoisParamsRequestData[],
): string[] {
  return getPOIsTypeFromDataRequest(Poi.POIsClasification.CustomPOIs, data)
}

export function getPOIsByTypesFromFilter(
  filter: FilterByPoisParamsRequestData[],
): Poi.TypesOfPOIs {
  const categories: string[] = getCategoriesFromDataRequest(filter)
  const brands: string[] = getBrandsFromDataRequest(filter)
  const locations: string[] = getLocationsFromDataRequest(filter)
  const customPOIs: string[] = getCustomPOIsFromDataRequest(filter)
  const tags: string[] = getTagsFromDataRequest(filter)

  return {
    categories,
    brands,
    locations,
    tags,
    custom_POIs: customPOIs,
  }
}

export function getFilterByPoisParamsRequestFromPoisfilter(
  filter: PoisFilter,
): Poi.FilterByPoisParamsRequest {
  const {
    categories,
    brands,
    locations,
    tags,
    custom_POIs: customPOIs,
  } = getPOIsByTypesFromFilter(filter.data)
  const maxDistance =
    getAppMetadata().units.distance === 'miles'
      ? milesToMeter(filter.max_distance)
      : filter.max_distance
  const minDistance =
    getAppMetadata().units.distance === 'miles'
      ? milesToMeter(filter.min_distance)
      : filter.min_distance
  const maxAssetsPerPoi =
    filter.max_num_panels && filter.max_num_panels > 0
      ? filter.max_num_panels
      : -1
  return {
    operation: filter.operation ?? Poi.POIsFilterOperation.Union,
    categories,
    brands,
    locations,
    tags,
    custom_POIs: customPOIs,
    max_distance: maxDistance,
    min_distance: minDistance,
    distance: filter.distance,
    method: filter.method,
    max_assets_per_poi: maxAssetsPerPoi,
  }
}

function isFilterNotToBeApplied(filter: PoisFilter): boolean {
  return (
    filter === undefined ||
    filter.data === undefined ||
    filter.data.length === 0
  )
}

function isAnIntersectionOperationWithMultiplePOIs(
  filter: PoisFilter,
): boolean {
  return (
    filter.operation === Poi.POIsFilterOperation.Intersection &&
    filter.data.length > 1
  )
}

async function fetchAssetsApplyingIntersectionPOIsFilter(
  metadata: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  rawPOIsFilter: PoisFilter,
  poisFilter: Poi.FilterByPoisParamsRequest,
  countryIds: number[],
  assetsIds: string[],
): Promise<string[]> {
  const promises = rawPOIsFilter.data.map((poiData) => {
    const filterWithOnlyOnePOI = { ...poisFilter }
    filterWithOnlyOnePOI.custom_POIs = []
    filterWithOnlyOnePOI.categories = []
    filterWithOnlyOnePOI.brands = []
    filterWithOnlyOnePOI.tags = []
    filterWithOnlyOnePOI.locations = []
    // max assets by POI
    filterWithOnlyOnePOI.max_assets_per_poi =
      poisFilter.max_assets_per_poi || -1

    switch (poiData.type) {
      case Poi.POIsClasification.CustomPOIs:
        filterWithOnlyOnePOI.custom_POIs = [poiData.id]
        break
      case Poi.POIsClasification.Category:
        filterWithOnlyOnePOI.categories = [poiData.id]
        break
      case Poi.POIsClasification.Brand:
        filterWithOnlyOnePOI.brands = [poiData.id]
        break
      case Poi.POIsClasification.Tags:
        filterWithOnlyOnePOI.tags = [poiData.id]
        break
      case Poi.POIsClasification.Locations:
        filterWithOnlyOnePOI.locations = [poiData.id]
        break
    }

    // TODO: update with assetIds - line 196
    const promise = PoiService.filterAssetsByPois(
      metadata,
      environment,
      filterWithOnlyOnePOI,
      countryIds,
      assetsIds,
    )

    return promise
  })

  const promisesResult = await Promise.all(promises)

  const filteredAssets = promisesResult.reduce((acc, curIds) => {
    return acc.filter((assetId) => curIds.includes(assetId))
  })

  return filteredAssets
}

export async function filterAssetsByProximity(
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
  ignoreMaxAssetsPerPOI = true,
): Promise<FilterAppliedResult> {
  // console.debug('proximity filter:', { filter })
  const filterProximity = filter.proximity

  if (isFilterNotToBeApplied(filterProximity)) {
    return { assets, filterApplied: false }
  }

  const poisFilter = getFilterByPoisParamsRequestFromPoisfilter(filterProximity)
  const countryIds = filter.countries

  let assetsIds: string[] = []

  if (!ignoreMaxAssetsPerPOI && poisFilter.max_assets_per_poi > 0) {
    assetsIds = assets.map((asset) => asset.properties.id)
  }

  if (ignoreMaxAssetsPerPOI) {
    poisFilter.max_assets_per_poi = 0
  }

  const metadata = getAppMetadata()
  const isAnIntersectionOperation =
    isAnIntersectionOperationWithMultiplePOIs(filterProximity)
  let filteredAssetsId: string[] = []
  if (isAnIntersectionOperation) {
    filteredAssetsId = await fetchAssetsApplyingIntersectionPOIsFilter(
      metadata,
      environment,
      filterProximity,
      poisFilter,
      countryIds,
      assetsIds,
    )
  } else {
    filteredAssetsId = await PoiService.filterAssetsByPois(
      metadata,
      environment,
      poisFilter,
      countryIds,
      assetsIds,
    )
  }
  const filteredAssets: AssetDataGeoJsonLayer[] = []
  const assetsMap = new Map<string, string>()
  filteredAssetsId.forEach((asset) => assetsMap.set(asset, asset))
  assets.forEach((asset) => {
    const assetInFilteredAssets = assetsMap.get(asset.properties.id)
    if (assetInFilteredAssets !== undefined) {
      filteredAssets.push(asset)
    }
  })
  return { assets: filteredAssets, filterApplied: true }
}
