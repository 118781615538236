export interface AudienceClass {
  ids: string[]
  name: string
}

export interface UserAudiencesGroup {
  audiences: AudienceClass[]
  name: string
}

export enum AudienceFeatureVersion {
  V1 = 1,
  V2 = 2,
}
