import i18n from '@/i18n'

export function getLocaleValueFor(
  key: string,
  throwExceptionIfNotFound = false,
) {
  if (!key) {
    const msg = 'Locale key must contain a value'
    if (throwExceptionIfNotFound) {
      throw new Error(msg)
    }
    console.error(msg)
    return ''
  }

  const localeValue = i18n.t(key).toString()
  if (localeValue === key) {
    const msg = `Locale key ${key} not found`
    if (throwExceptionIfNotFound) {
      throw new Error(msg)
    }
    console.warn(msg)
  }

  return localeValue
}
