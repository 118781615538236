import { Environment, Metadata, SharedPlan } from '@workspaces/types'
import sharedPlanService from '@/services/sharedPlan.service'

interface SharedPlanPasswordManagerInterface {
  isPasswordRequired(): boolean
  isActive(): boolean
  setPassword(password: string): void
  getPassword(): string
  init(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    sharedPlanId: string,
  ): Promise<void>
}
export default class SharedPlanPasswordManager
implements SharedPlanPasswordManagerInterface {
  private static instance: SharedPlanPasswordManagerInterface
  private password: string | undefined
  private passwordRequired: boolean
  private alreaydInit: boolean
  private isPlanActive: boolean

  private constructor() {
    this.alreaydInit = false
    this.password = undefined
    this.passwordRequired = true
    this.isPlanActive = false
  }

  public async init(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    sharedPlanId: string,
  ): Promise<void> {
    if (this.alreaydInit) {
      throw new Error(
        '🔴 SharedPlanPasswordManager --> Already initialized. Cannot be intialized more than once',
      )
    }
    const sharedPlanState = await sharedPlanService.isPasswordRequired(
      meta,
      environment,
      sharedPlanId,
    )
    this.passwordRequired = sharedPlanState.isPasswordRequired
    this.isPlanActive = sharedPlanState.isActive
  }

  public static getInstance(): SharedPlanPasswordManagerInterface {
    if (!SharedPlanPasswordManager.instance) {
      SharedPlanPasswordManager.instance = new SharedPlanPasswordManager()
    }
    return SharedPlanPasswordManager.instance
  }

  public isPasswordRequired(): boolean {
    return this.passwordRequired
  }

  public isActive(): boolean {
    return this.isPlanActive
  }

  public getPassword(): string {
    if (!this.password) {
      throw new Error(
        '🔴 SharedPlanPasswordManager --> Trying to retreive a password but NO password has been provided',
      )
    }
    return this.password
  }

  public setPassword(password: string): void {
    this.password = password
  }
}
