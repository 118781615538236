import { Insight } from '@workspaces/types'

export const InsightTemplateUS: Insight.InsightTransformation[] = [
  {
    audienceClassName:
      'Demographics >> Highest Level of Education for Head of Household >> Head of HH Education: High School Graduate (or completed GED)',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Highest Level of Education for Head of Household',
    humanNameAttr3: 'HS Graduate',
    position: { attr2: 1, attr3: 1 },
  },
  {
    audienceClassName:
      'Demographics >> Highest Level of Education for Head of Household >> Some College',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Highest Level of Education for Head of Household',
    humanNameAttr3: 'Some College',
    position: { attr2: 1, attr3: 2 },
  },
  {
    audienceClassName:
      "Demographics >> Highest Level of Education for Head of Household >> Head of HH Edu: Associate's Degree",
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Highest Level of Education for Head of Household',
    humanNameAttr3: "Associate's Degree",
    position: { attr2: 1, attr3: 3 },
  },
  {
    audienceClassName:
      "Demographics >> Highest Level of Education for Head of Household >> Head of HH Education: Bachelor's Degree",
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Highest Level of Education for Head of Household',
    humanNameAttr3: "Bachelor's Degree",
    position: { attr2: 1, attr3: 4 },
  },
  {
    audienceClassName:
      "Demographics >> Highest Level of Education for Head of Household >> Head of HH Edu: Master's Degree",
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Highest Level of Education for Head of Household',
    humanNameAttr3: "Master's Degree",
    position: { attr2: 1, attr3: 5 },
  },
  {
    audienceClassName:
      'Demographics >> Household Income >> Less than $50,000 household income range',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Household Income',
    humanNameAttr3: '<$50k',
    position: { attr2: 2, attr3: 1 },
  },
  {
    audienceClassName:
      'Demographics >> Household Income >> $50,000-$74,999 household income range',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Household Income',
    humanNameAttr3: '$50k-$74.9k',
    position: { attr2: 2, attr3: 2 },
  },
  {
    audienceClassName:
      'Demographics >> Household Income >> $75,000-$99,999 household income range',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Household Income',
    humanNameAttr3: '$75k-$99.9k',
    position: { attr2: 2, attr3: 3 },
  },
  {
    audienceClassName:
      'Demographics >> Household Income >> $100,000-$149,999 household income range',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Household Income',
    humanNameAttr3: '$100k-$149.9k',
    position: { attr2: 2, attr3: 4 },
  },
  {
    audienceClassName:
      'Demographics >> Household Income >> $150,000+ household income range',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Household Income',
    humanNameAttr3: '$150k+',
    position: { attr2: 2, attr3: 5 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 18-24',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '18-24',
    position: { attr2: 3, attr3: 1 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 25-34',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '25-34',
    position: { attr2: 3, attr3: 2 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 35-44',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '35-44',
    position: { attr2: 3, attr3: 3 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 45-54',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '45-54',
    position: { attr2: 3, attr3: 4 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 55-64',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '55-64',
    position: { attr2: 3, attr3: 5 },
  },
  /*
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 18+',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '18+',
    position: { attr2: 3, attr3: 6 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 21+',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '21+',
    position: { attr2: 3, attr3: 7 },
  },
  */
  {
    audienceClassName: 'Demographics >> Individual- Age Range >> 65+',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Age Range',
    humanNameAttr3: '65+',
    position: { attr2: 3, attr3: 8 },
  },
  {
    audienceClassName:
      'Demographics >> Marital Status >> Marital Status: Divorced/Separated',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Marital Status',
    humanNameAttr3: 'Divorced / Separated',
    position: { attr2: 4, attr3: 1 },
  },
  {
    audienceClassName:
      'Demographics >> Marital Status >> Marital Status: Married',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Marital Status',
    humanNameAttr3: 'Married',
    position: { attr2: 4, attr3: 2 },
  },
  {
    audienceClassName:
      'Demographics >> Marital Status >> Marital Status: Not Married',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Marital Status',
    humanNameAttr3: 'Not Married',
    position: { attr2: 4, attr3: 3 },
  },
  {
    audienceClassName:
      'Demographics >> Marital Status >> Marital Status: Single (Never Married)',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Marital Status',
    humanNameAttr3: 'Single (Never Married)',
    position: { attr2: 4, attr3: 4 },
  },
  {
    audienceClassName:
      'Demographics >> Marital Status >> Marital Status: Widowed',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Marital Status',
    humanNameAttr3: 'Widowed',
    position: { attr2: 4, attr3: 5 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Gender >> Male',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Gender',
    humanNameAttr3: 'Male',
    position: { attr2: 5, attr3: 1 },
  },
  {
    audienceClassName: 'Demographics >> Individual- Gender >> Female',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Gender',
    humanNameAttr3: 'Female',
    position: { attr2: 5, attr3: 2 },
  },
  {
    audienceClassName:
      'Demographics >> Race/Ethnicity >> All African American Ethnic Groups',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Race/Ethnicity',
    humanNameAttr3: 'All African American Ethnic Groups',
    position: { attr2: 6, attr3: 1 },
  },
  {
    audienceClassName: 'Demographics >> Race/Ethnicity >> Caucasian',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Race/Ethnicity',
    humanNameAttr3: 'Caucasian',
    position: { attr2: 6, attr3: 2 },
  },
  {
    audienceClassName: 'Demographics >> Race/Ethnicity >> Hispanic',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Race/Ethnicity',
    humanNameAttr3: 'Hispanic',
    position: { attr2: 6, attr3: 3 },
  },
  {
    audienceClassName: 'Demographics >> Race/Ethnicity >> Middle Eastern',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Race/Ethnicity',
    humanNameAttr3: 'Middle Eastern',
    position: { attr2: 6, attr3: 4 },
  },
  {
    audienceClassName: 'Demographics >> Race/Ethnicity >> Native American',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Race/Ethnicity',
    humanNameAttr3: 'Native American',
    position: { attr2: 6, attr3: 5 },
  },
  {
    audienceClassName: 'Demographics >> Race/Ethnicity >> Polynesian',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Race/Ethnicity',
    humanNameAttr3: 'Polynesian',
    position: { attr2: 6, attr3: 6 },
  },
  {
    audienceClassName:
      'Demographics >> Race/Ethnicity >> Southeast/Central/Southwest Asian',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Race/Ethnicity',
    humanNameAttr3: 'Southeast/Central/Southwest Asian',
    position: { attr2: 6, attr3: 7 },
  },
  {
    audienceClassName:
      'Demographics >> Employment Status >> Employment Status: Employed Full or Part Time',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Employment Status',
    humanNameAttr3: 'Full or Part Time',
    position: { attr2: 7, attr3: 1 },
  },
  {
    audienceClassName:
      'Demographics >> Employment Status >> Employment Status: Retired',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Employment Status',
    humanNameAttr3: 'Retired',
    position: { attr2: 7, attr3: 2 },
  },
  {
    audienceClassName:
      'Demographics >> Employment Status >> Currently Self-Employed',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Employment Status',
    humanNameAttr3: 'Self-Employed',
    position: { attr2: 7, attr3: 3 },
  },
  {
    audienceClassName:
      'Demographics >> Home Ownership >> Home Ownership: Own Primary Residence',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Home Ownership',
    humanNameAttr3: 'Own Primary Residence',
    position: { attr2: 8, attr3: 1 },
  },
  {
    audienceClassName:
      'Demographics >> Home Ownership >> Home Ownership: Rent Primary Residence',
    humanNameAttr1: 'Demographics',
    humanNameAttr2: 'Home Ownership',
    humanNameAttr3: 'Rent Primary Residence',
    position: { attr2: 8, attr3: 2 },
  },
]
