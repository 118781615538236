export enum State {
  Draft = 'DRAFT',
  BookingRequest = 'BOOKING_REQUEST',
  BookingConfirmedByAdmin = 'BOOKING_CONFIRMED_BY_ADMIN', // 48h timer starts once this state is set
  BookingConfirmedByAgency = 'BOOKING_CONFIRMED_BY_AGENCY', // 48h timer is stopped once this state is set
  ReBooking = 'RE_BOOK',
  Expired = 'EXPIRED',
  Booked = 'BOOKED', // Agency can cancel at any time until 48h before the start
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
}

export interface Package {
  package_id: number
  package: string
  gross_price: number
  posting: number
  production: number
  contacts: number
  panels: number
  gross_cpt: number
}

export interface PackageClassifiactionValues {
  l1: string[]
  l2: string[]
  l3: string[]
  l4: string[]
}

export interface PackageFilterClassification
  extends PackageClassifiactionValues {
  digital?: boolean
}
export interface PackageClassifcation {
  digital?: boolean
  l1: string | null
  l2: string[] | null
  l3: string[] | null
  l4: string[] | null
}

export interface PackageFilter {
  priceRange: [number, number]
  dateRange: [Date, Date] | null
  panelType: PackageFilterClassification
}

export interface PlanPackageActions {
  state: State
  timestamp: Date
  user: string
}

export interface PlanPackage {
  state: State
  id: number
  name: string
  price: number
  assets: number
  impressions: number
  availability: number
  actions: PlanPackageActions[]
  bookedConfirmByAdminAt: Date | undefined
  expirationForAgencyBookingConfirmation: Date | undefined
  bookedFinalizedAt: Date | undefined
  expirationForAgencyCancellation: Date | undefined

  filter: PackageFilter
}

export interface PackageWithAllData extends Package, PackageClassifcation {}

export interface PlanPackagePrice {
  grossPrice: number
  discounts: {
    freePercentage: number
    mediaAgencyPercentage: number
    otherPercentage: number
  }
  fees: {
    production: number
    assetAllocation: number
    materialHandling: number
    photography: number
  }
  totalNet: number
}

export interface PlanPackagePriceUnformatted {
  grossPrice: number | string
  discounts: {
    freePercentage: number | string
    mediaAgencyPercentage: number | string
    otherPercentage: number | string
  }
  fees: {
    production: number | string
    assetAllocation: number | string
    materialHandling: number | string
    photography: number | string
  }
  totalNet: number | string
}

export interface PackageTableData {
  id: number
  name: string
  panels: number
  price: number
  availability: number
  impressions: number
}

export interface PackageFilterData {
  priceRange: [number, number]
  dateRange: [string, string] | null
  panelType: PackageFilterClassification
}
