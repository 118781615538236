import Api from './api'
import { parseParams } from '@/utils/queryParams'
import { convertCsvToExcelFile } from '@/export/xlsx.helper'
import { waitMS } from '@/api/utils'

export const CUSTOM_DISPLAY_TYPES = {
  GEOPATH: {
    label: 'Geopath ID',
    value: 'GeopathId',
  },
  PLANTUNIT: {
    label: 'Plant Unit ID',
    value: 'PlantUnitId',
  },
}

export default {
  async getExportedAssets(params = {}, options) {
    async function originalRequest(budgetId) {
      const res = await Api.post(
        '/v2/assets/export',
        { ...params, isoline_budget_id: budgetId },
        options,
      )
      if (res.status !== 202) {
        throw new Error(
          `Status code expected is 202 but got ${res.status}. Job ID is ${res.job_id}`,
        )
      }
      const url = `/v2/jobs/${res.data.job_id}`
      let job = await Api.get(url)
      while (job.data.state === 'RUNNING' || job.data.state === 'QUEUED') {
        await waitMS(1500)
        job = await Api.get(url)
      }
      if (job.data.state === 'COMPLETED') {
        const csvContent = await Api.get(
          `/v2/assets/export?job_id=${res.data.job_id}`,
        )
        return csvContent.data
      } else {
        window.alert(
          `Exporting operation has not finished succesfully. Job ID is ${res.job_id}`,
        )
        throw new Error(
          '[assetsService.getExportedAssets] exporting job has not finished succesfully',
          job.data,
        )
      }
    }

    const hasIsoline =
      params.close_to?.mode && params.close_to?.mode !== 'RADIUS'
    if (!hasIsoline) {
      return await originalRequest()
    }

    const { data: budget } = await Api.post(
      '/v2/assets/isogeometries/budgets',
      { ...params, ...params.close_to },
    )
    const {
      monthly_quota: monthlyQ,
      used_quota: usedQ,
      to_use_quota: quota,
      id: budgetId,
    } = budget
    const availableQ = monthlyQ - usedQ
    if (quota > availableQ) {
      const msg =
        "You don't have enough isoline credits to complete this request. Please contact support."
      window.alert(msg)
      throw new Error(msg)
    }
    if (quota > 0) {
      const confirmation = window.confirm(
        `This request will consume ${quota} isoline credits from a total of ${availableQ} for this month. Continue?`,
      )
      if (!confirmation) {
        throw new Error('[assetsService.getAssets] request cancelled by user')
      }
    }

    const firstTry = await originalRequest(budgetId)
    // if status code is 202, it means a job is pending, and original request cannot return the new data yet
    if (firstTry.status_code === 202) {
      let job = await Api.get(`/v2/jobs/${firstTry.job_id}`)
      while (job.data.state !== 'COMPLETED') {
        if (job.data.state === 'FAILED') {
          window.alert(
            `Backend job failed for generating isolines. Job ID is ${firstTry.job_id}`,
          )
          throw new Error(
            '[assetsService.getAssets] isolines job FAILED',
            job.data,
          )
        }
        await waitMS(1000)
        job = await Api.get(`/v2/jobs/${firstTry.job_id}`)
      }
      return await originalRequest(budgetId)
    } else {
      return firstTry
    }
  },
  async getAssets(params = {}, options) {
    async function originalRequest(budgetId) {
      let res = await Api.post(
        '/v2/assets/list',
        { ...params, isoline_budget_id: budgetId },
        options,
      )
      if (res.status === 200 && res.data.pois_filtered === 1) {
        const newParams = {
          ...params,
          tmp_pois_filter_table: res.data.table_name,
        }
        res = await Api.post(
          '/v2/assets/list',
          { ...newParams, isoline_budget_id: budgetId },
          options,
        )
      }
      return res.data
    }

    const hasIsoline =
      params.close_to?.mode && params.close_to?.mode !== 'RADIUS'
    if (!hasIsoline) {
      return await originalRequest()
    }

    const { data: budget } = await Api.post(
      '/v2/assets/isogeometries/budgets',
      { ...params, ...params.close_to },
    )
    const {
      monthly_quota: monthlyQ,
      used_quota: usedQ,
      to_use_quota: quota,
      id: budgetId,
    } = budget
    const availableQ = monthlyQ - usedQ
    if (quota > availableQ) {
      const msg =
        "You don't have enough isoline credits to complete this request. Please contact support."
      window.alert(msg)
      throw new Error(msg)
    }
    if (quota > 0) {
      const confirmation = window.confirm(
        `This request will consume ${quota} isoline credits from a total of ${availableQ} for this month. Continue?`,
      )
      if (!confirmation) {
        throw new Error('[assetsService.getAssets] request cancelled by user')
      }
    }

    const firstTry = await originalRequest(budgetId)
    // if status code is 202, it means a job is pending, and original request cannot return the new data yet
    if (firstTry.status_code === 202) {
      let job = await Api.get(`/v2/jobs/${firstTry.job_id}`)
      while (job.data.state !== 'COMPLETED') {
        if (job.data.state === 'FAILED') {
          window.alert(
            `Backend job failed for generating isolines. Job ID is ${firstTry.job_id}`,
          )
          throw new Error(
            '[assetsService.getAssets] isolines job FAILED',
            job.data,
          )
        }
        await waitMS(1000)
        job = await Api.get(`/v2/jobs/${firstTry.job_id}`)
      }
      return await originalRequest(budgetId)
    } else {
      return firstTry
    }
  },
  async getAssetsTrainLines(params = {}, options) {
    async function originalRequest(budgetId) {
      const res = await Api.post(
        '/v2/assets/railways',
        { ...params, isoline_budget_id: budgetId },
        options,
      )
      return res.data
    }

    const hasIsoline =
      params.close_to?.mode && params.close_to?.mode !== 'RADIUS'
    if (!hasIsoline) {
      return await originalRequest()
    }

    const { data: budget } = await Api.post(
      '/v2/assets/isogeometries/budgets',
      { ...params, ...params.close_to },
    )
    const {
      monthly_quota: monthlyQ,
      used_quota: usedQ,
      to_use_quota: quota,
      id: budgetId,
    } = budget
    const availableQ = monthlyQ - usedQ
    if (quota > availableQ) {
      const msg =
        "You don't have enough isoline credits to complete this request. Please contact support."
      window.alert(msg)
      throw new Error(msg)
    }
    if (quota > 0) {
      const confirmation = window.confirm(
        `This request will consume ${quota} isoline credits from a total of ${availableQ} for this month. Continue?`,
      )
      if (!confirmation) {
        throw new Error('[assetsService.getAssets] request cancelled by user')
      }
    }

    const firstTry = await originalRequest(budgetId)
    // if status code is 202, it means a job is pending, and original request cannot return the new data yet
    if (firstTry.status_code === 202) {
      let job = await Api.get(`/v2/jobs/${firstTry.job_id}`)
      while (job.data.state !== 'COMPLETED') {
        if (job.data.state === 'FAILED') {
          window.alert(
            `Backend job failed for generating isolines. Job ID is ${firstTry.job_id}`,
          )
          throw new Error(
            '[assetsService.getAssets] isolines job FAILED',
            job.data,
          )
        }
        await waitMS(1000)
        job = await Api.get(`/v2/jobs/${firstTry.job_id}`)
      }
      return await originalRequest(budgetId)
    } else {
      return firstTry
    }
  },
  getAsset(id, params = {}) {
    const urlParams = parseParams(params)
    return Api.get(`/v2/assets/${id}?${urlParams}`).then((res) => res.data)
  },
  getAssetInsights(id, params = {}) {
    const urlParams = parseParams(params)
    return Api.get(`/v2/assets/${id}/insights?${urlParams}`).then(
      (res) => res.data,
    )
  },
  exportAssets(params, fileName = 'export-asset.xlsx') {
    return this.getExportedAssets(params).then((response) => {
      const csvOpts = {
        columnsToDoNotApplyNumberFormat: [],
        csvString: response,
        applyNumberFormat: true,
        tabName: 'Sheet1',
      }
      convertCsvToExcelFile([csvOpts], fileName)
      return response
    })
  },
  async createAssetsEntity({ countries, file, idType }) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('countries', countries.join(','))
    formData.append('id_type', idType)
    const res = await Api.post('v2/assets/set', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return res.data
  },
}
