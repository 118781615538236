export enum Basemap {
  OpenStreetMaps = 'OpenStreetMaps',
  GoogleMaps = 'GoogleMaps',
  GoogleMapsPositron = 'GoogleMapsPositron',
  GoogleMapsSatellite = 'GoogleMapsSatellite',
  GoogleMapsHybrid = 'GoogleMapsHybrid',
}

export interface BasemapApiKey {
  dev: string
  stg: string
  pro: string
}

export interface BasemapOption {
  id: Basemap
  name: string
  apiKey?: BasemapApiKey
}

export interface ViewState {
  center: [number, number]
  zoom: number
  provider: string
}
