import { getAppMetadata } from '../metadata/metadata'
import { AssetDataGeoJsonLayer } from './asset.type'
import { FilterAppliedResult } from './plan.type'
import CustomGeoboundaryService from '@/services/custom-geoboundary.service'
import {
  filterAssetsByCustomGeoboundary,
  filterAssetsByExcludedGeoboundary,
  filterAssetsByGeoboundary,
  filterAssetsByFileGeoboundary,
  unionOfAssets,
} from './geoboundary.helper'
import { Filter, customGeoboundaryDatasetsContent } from './filter.type'
import { deserializeFilters } from '../deserializeFilters'
import { Environment, Geoboundary } from '@workspaces/types'

export async function cacheCustomGeoboundaryDatasetsInFilter(
  environmentResolver: Environment.EnvironmentResolver,
  filter: Filter,
): Promise<number> {
  // console.debug('[cacheCustomGeoboundaryDatasetsInFilter] Start')
  const filterGeoboundary = filter.geoboundaries
  // Detectar si hay datos para los regions 16 y 17
  const customGeoboundaryDatasets = [
    ...(filterGeoboundary.region_16 ?? []),
    ...(filterGeoboundary.region_17 ?? []),
  ]
  const customGeobundaryDatasetsCount = customGeoboundaryDatasets.length
  if (customGeobundaryDatasetsCount === 0) {
    // console.debug(
    //   '[cacheCustomGeoboundaryDatasetsInFilter]  NO custom geoboundary dataset detected in this subfilter',
    // )
    return -1
  }

  // Descargar los datasets de firestore mediante ids
  if (
    (filter.customGeoboundaryDatasetsCachedContent &&
      filter.customGeoboundaryDatasetsCachedContent.length > 0) ||
    (filter.customExcludedGeoboundaryDatasetsCachedContent &&
      filter.customExcludedGeoboundaryDatasetsCachedContent.length > 0)
  ) {
    // console.debug(
    //   '[cacheCustomGeoboundaryDatasetsInFilter]  Data is already set in customGeoboundaryDatasetsCachedContent, no need to fetch it',
    // )
    return 0
  } else {
    // console.debug(
    //   '[cacheCustomGeoboundaryDatasetsInFilter]  Fetching custom geoboundary dataset data to run subfilter. Detected datasets:',
    //   customGeobundaryDatasetsCount,
    // )
    const cachedData: customGeoboundaryDatasetsContent[] = []
    const cachedDataExcluded: customGeoboundaryDatasetsContent[] = []
    const meta = getAppMetadata()
    for (let i = 0; i < customGeobundaryDatasetsCount; i++) {
      const dataset = customGeoboundaryDatasets[i]
      const { id } = dataset
      // console.debug(
      //   `[cacheCustomGeoboundaryDatasetsInFilter]  Fetching dataset from firestore with id ${id}`,
      // )
      const datasetData = await CustomGeoboundaryService.getCustomGeoboundary(
        meta,
        environmentResolver,
        String(id),
      )
      datasetData.filters = deserializeFilters(datasetData.filters)
      // console.debug(
      //   `[cacheCustomGeoboundaryDatasetsInFilter]        Fetched dataset with id ${id}`,
      // )
      let geoboundaries = datasetData.filters[0].geoboundaries
      if (dataset.exclude !== undefined && dataset.exclude === true) {
        geoboundaries = setAllGeoboundariesToExcluded(geoboundaries)
      }
      const data = {
        id: String(dataset.id),
        filterForCustomGeoboundaryDatasetRegions:
          datasetData.filters[0].geoboundaries,
        filterForCustomGeoboundaryDatasetCustomGeoboundaries:
          datasetData.filters[0].polygon_geom,
      }
      if (dataset.exclude !== undefined && dataset.exclude === true) {
        cachedDataExcluded.push(data)
      } else {
        cachedData.push(data)
      }
    }
    filter.customGeoboundaryDatasetsCachedContent = cachedData
    filter.customExcludedGeoboundaryDatasetsCachedContent = cachedDataExcluded
    // console.debug(
    //   `[cacheCustomGeoboundaryDatasetsInFilter]  Datasets fetched ${cachedData.length}`,
    // )
    return cachedData.length
  }
}

export async function filterAssetsByCustomGeoboundaryDatasets(
  environmentResolver: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
): Promise<FilterAppliedResult> {
  const res = await cacheCustomGeoboundaryDatasetsInFilter(
    environmentResolver,
    filter,
  )
  if (res === -1) {
    return { assets: [], filterApplied: false }
  }

  let uniqueUnionOfAssetsFiltered: AssetDataGeoJsonLayer[] = []
  let filterApplied = false
  // Filtrar los assets por regiones conocidas filtrando los assetsId únicos
  if (
    filter.customGeoboundaryDatasetsCachedContent &&
    filter.customGeoboundaryDatasetsCachedContent.length > 0
  ) {
    const customGeobundaryDatasetsCount =
      filter.customGeoboundaryDatasetsCachedContent.length
    for (let i = 0; i < customGeobundaryDatasetsCount; i++) {
      console.debug(
        '                        Processing custom geoboundary dataset ',
        i,
      )
      const dataset = filter.customGeoboundaryDatasetsCachedContent[i]
      console.debug(
        `                        Applying geoboundary filter to dataset ${i}`,
      )
      const geoboundaryPartialFilterResult: FilterAppliedResult =
        filterAssetsByGeoboundary(
          assets,
          dataset.filterForCustomGeoboundaryDatasetRegions,
          dataset.filterForCustomGeoboundaryDatasetCustomGeoboundaries,
        )
      filterApplied =
        filterApplied || geoboundaryPartialFilterResult.filterApplied
      console.debug(
        `                        Result for applying geoboundary filter to dataset ${i}`,
      )
      console.debug(
        '                        Assets from applying filter:',
        geoboundaryPartialFilterResult.assets,
      )
      uniqueUnionOfAssetsFiltered = unionOfAssets(
        uniqueUnionOfAssetsFiltered,
        geoboundaryPartialFilterResult.assets,
      )
      console.debug(
        '                        Union os partial results:',
        uniqueUnionOfAssetsFiltered,
      )
      const region18 =
        dataset.filterForCustomGeoboundaryDatasetRegions.region_18
      if (Array.isArray(region18) && region18.length) {
        console.debug(
          `                        Applying imported files geoboundary filter to dataset ${i}`,
        )
        const fileGeoboundaryPartialFilterResult: FilterAppliedResult =
          await filterAssetsByFileGeoboundary(
            environmentResolver,
            getAppMetadata(),
            assets,
            region18,
          )
        console.debug(
          `                        Result for applying imported files geoboundary filter to dataset ${i}`,
        )
        uniqueUnionOfAssetsFiltered = unionOfAssets(
          uniqueUnionOfAssetsFiltered,
          fileGeoboundaryPartialFilterResult.assets,
        )
        filterApplied =
          filterApplied || fileGeoboundaryPartialFilterResult.filterApplied
        console.debug(
          '                        Union os partial results:',
          uniqueUnionOfAssetsFiltered,
        )
      }
    }
  }

  // Devolver los assets filtrados
  const result: FilterAppliedResult = {
    assets: uniqueUnionOfAssetsFiltered,
    filterApplied,
  }
  // console.debug('[filterAssetsByCustomGeoboundaryDatasets] End', result)
  return result
}

function setAllGeoboundariesToExcluded(
  filter: Geoboundary.AssetFilterGeoboundaries,
): Geoboundary.AssetFilterGeoboundaries {
  const updatedFilter = { ...filter }
  const filterFlattened = Object.values(updatedFilter).flat()
  filterFlattened.forEach((regionType) => {
    regionType.exclude = true
  })
  return updatedFilter
}

export async function filterAssetsByExcludedCustomGeoboundaryDatasets(
  environmentResolver: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
): Promise<FilterAppliedResult> {
  const res = await cacheCustomGeoboundaryDatasetsInFilter(
    environmentResolver,
    filter,
  )
  if (res === -1) {
    return { assets, filterApplied: false }
  }

  let assetsFiltered: AssetDataGeoJsonLayer[] = [...assets]
  let filterApplied = false
  // Filtrar los assets por regiones conocidas filtrando los assetsId únicos
  if (
    filter.customExcludedGeoboundaryDatasetsCachedContent &&
    filter.customExcludedGeoboundaryDatasetsCachedContent.length > 0
  ) {
    const customExcludedGeoboundaryDatasetsCachedContentCount =
      filter.customExcludedGeoboundaryDatasetsCachedContent.length
    for (
      let i = 0;
      i < customExcludedGeoboundaryDatasetsCachedContentCount;
      i++
    ) {
      const dataset = filter.customExcludedGeoboundaryDatasetsCachedContent[i]
      // console.debug(
      //   `[filterAssetsByCustomGeoboundaryDatasets]  Applying geoboundary filter to dataset ${i}`,
      // )
      dataset.filterForCustomGeoboundaryDatasetRegions =
        setAllGeoboundariesToExcluded(
          dataset.filterForCustomGeoboundaryDatasetRegions,
        )
      const geoboundaryPartialFilterResult: FilterAppliedResult =
        filterAssetsByExcludedGeoboundary(
          assets,
          dataset.filterForCustomGeoboundaryDatasetRegions,
          // dataset.filterForCustomGeoboundaryDatasetCustomGeoboundaries,
        )
      assetsFiltered = geoboundaryPartialFilterResult.assets
      filterApplied =
        filterApplied || geoboundaryPartialFilterResult.filterApplied
      // console.debug(
      //   `[filterAssetsByCustomGeoboundaryDatasets]      Result for applying geoboundary filter to dataset ${i}`,
      // )
      // console.debug(
      //   '[filterAssetsByCustomGeoboundaryDatasets]      Assets from applying filter:',
      //   geoboundaryPartialFilterResult.assets,
      // )

      const geoboundaryPartialFilterResult2: FilterAppliedResult =
        filterAssetsByCustomGeoboundary(
          assetsFiltered,
          dataset.filterForCustomGeoboundaryDatasetCustomGeoboundaries,
          false,
        )
      assetsFiltered = geoboundaryPartialFilterResult2.assets
      filterApplied =
        filterApplied || geoboundaryPartialFilterResult2.filterApplied
      const region18 =
        dataset.filterForCustomGeoboundaryDatasetRegions.region_18
      if (Array.isArray(region18) && region18.length) {
        const fileGeoboundaryPartialFilterResult: FilterAppliedResult =
          await filterAssetsByFileGeoboundary(
            environmentResolver,
            getAppMetadata(),
            assetsFiltered,
            region18,
            false,
          )

        assetsFiltered = fileGeoboundaryPartialFilterResult.assets
        filterApplied =
          filterApplied || fileGeoboundaryPartialFilterResult.filterApplied
      }

      // console.debug(
      //   '[filterAssetsByCustomGeoboundaryDatasets]      Union os partial results:',
      //   uniqueUnionOfAssetsFiltered,
      // )
    }
  }

  // Devolver los assets filtrados
  const result: FilterAppliedResult = {
    assets: assetsFiltered,
    filterApplied,
  }
  // console.debug('[filterAssetsByCustomGeoboundaryDatasets] End', result)
  return result
}
