function stringSortByAlphabeticalOrder(a: string, b: string): number {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

export function sortArrayByAlphabeticalOrder(content: string[]): string[] {
  return content.sort((a: string, b: string) =>
    stringSortByAlphabeticalOrder(a, b),
  )
}

export function isArrayEmpty(array: unknown[]): boolean {
  try {
    const length = array.length
    return length === 0
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(
      'Checking if an array is empty but its undefined.\nConsidering it empty',
      error,
    )
    return true
  }
}

export function isNotEmptyArray(array: unknown): boolean {
  if (!array) {
    return false
  }

  if (!Array.isArray(array)) {
    return false
  }

  return array.length > 0
}
