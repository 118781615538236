export interface InsightParamsResquest {
  assets_id: string[]
  delivery: string
  country_ids: number[]
}

export interface InsightData {
  index: number
  market: number
  panel: number
}

export interface InsightImpressionsResult {
  week: number
  year: number
  impressions: number
  uniques: number
}

export interface InsightImpressionsData {
  timeseries: InsightImpressionsResult[]
  uniques: number
  weekly_variation: number
}

export interface InsightResult extends InsightData {
  name: string
}

export type InsightFormattedResult = {
  [K in string]: InsightFormattedResult | InsightData | InsightImpressionsData
}

export interface InsightTransformationOrder {
  attr2?: number
  attr3?: number
}
export interface InsightTransformation {
  audienceClassName: string
  groupedAduiencesClasses?: string[]
  humanNameAttr1: string
  humanNameAttr2: string
  humanNameAttr3: string
  position?: InsightTransformationOrder
}

export enum InsightProperties {
  Index,
  Market,
  Panel,
}
