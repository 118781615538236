import { Environment, Metadata, Geoboundary } from '@workspaces/types'
import { GeometryService } from '@workspaces/services'

export default {
  async getGeometriesCustomFileGeometries(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    fileIds: string[],
  ): Promise<Geoboundary.Geometry[]> {
    return await GeometryService.getGeometriesCustomFileGeometries(
      meta,
      environment,
      fileIds,
    )
  },
}
