export default class ErrorMessage extends Error {
  public readonly localeTitleKey: string
  public readonly localeMessageKey: string
  public readonly error: Error

  constructor(
    localeTitleKey: string,
    localeMessageKey: string,
    message: string,
    error: Error,
  ) {
    super(message)
    this.localeTitleKey = localeTitleKey
    this.localeMessageKey = localeMessageKey
    this.error = error
  }
}
